.read-more {
  color: #f49832;
  cursor: pointer;
}

.read-less {
  color: #f49832;
  cursor: pointer;
  margin-left: 16px;
}
