.commentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background: #121212;
  border-radius: 10px;
  overflow: hidden;
}

.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  /* margin-left: 0px;
  position: relative;
  border-bottom: 1px solid #f9f9f9; */
}

.title {
  color: #ffffff;
  margin: 0rem 1rem 1rem 1rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.commentItem {
  display: flex;
  flex-direction: column;
}

.commentItem div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.commentItem div span {
  margin-right: 0.5rem;
}

.commentItem div span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.commentItem div span:last-child {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
}

.commentAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1rem;
}

.commentList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
}

.commentList[data-fullscreen-comment-list] {
  height: 100%;
}

.commentList li {
  list-style-type: none;
  margin-bottom: 1rem;
  background: rgba(28, 28, 28, 0.64) !important;
  border-radius: 10px 10px 10px 0px;
  padding: 8px;
}

.commentList .transparant {
  background: rgba(28, 28, 28, 0.64) !important;
}

.commentList li > :first-child {
  margin-top: auto !important;
}

.commentListWrapper {
  overflow: auto;
  padding: 16px;
  flex: 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.overflow {
  flex-direction: column-reverse !important;
}

.commentListWrapper::-webkit-scrollbar {
  width: 10px;
}

.commentListWrapper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.commentListWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2e2e2e;
}

.hideScrollBar::-webkit-scrollbar {
  width: 0px;
}
.giftCommentContainer {
  display: flex;
  padding-left: 8px;
  height: 80px;
}

.giftCommentContainer > img {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
}

.giftCommentContainer > div {
  color: #f49832;
  display: flex;
  align-items: center;
  line-height: 12px;
}

.giftCommentContainer > div {
  left: 80px;
  top: 20px;

  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  display: inline-block;
  margin-left: 4px;
}

.giftCommentContainer > div > p {
  display: inline;
  line-height: 12px;
  font-size: 16px;
}

.bulletMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
}

.userJoin {
  display: flex;
  padding: 14px 16px;
  margin: 8px 16px;
  align-items: center;
  background-color: #1c1c1ca3;
  border-radius: 100px;
  box-sizing: border-box;
}

.userJoin > img {
  margin-right: 16px;
}

.userJoin > p {
  margin: 0;
}

.userJoin .joinMessage {
  padding-right: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  border-right: 1px solid black;
}

.userJoin .timeout {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-color);
  margin-left: 1rem;
}

.giftReceiver {
  color: var(--primary-color);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
