@font-face {
  font-family: Ooredoo;
  src: url(../../assets/ioh-assets/fonts/Ooredoo-Heavy.otf);
}

.historyWrapper {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.historyWrapper > p {
  margin: auto 0;
  font-size: 16px;
}

.container {
  background-color: #fff;
  padding: 1rem;
  min-height: 100vh;
}

.title {
  color: #000;
  /* font-size: 28px; */
  font-family: Ooredoo;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .item {
    padding: 5px 0 5px 0;
    margin: 0;
    width: 30%;
    height: 100%;
  }
}

@media only screen and (width: 1365px) {
  .item {
    max-width: 196px;
    width: 15%;
  }
}
