.button-pop-modal {
  position: relative;
  display: flex;
  height: 100%;
}

.pop-modal-dialog {
  position: fixed;
  background-color: var(--dark-modal-color);
  border-radius: 10px;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
  z-index: 8;
}

.pop-modal-content {
  position: relative;
}

.pop-modal-arrow {
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--dark-modal-color);
  border-top: 10px solid transparent;
}

.pop-modal-dialog:hover {
  opacity: 1;
}
