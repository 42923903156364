.info-item {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0%;
  padding: 5%;
  width: 100%;
  box-sizing: border-box;
  mix-blend-mode: normal;
  /* background: rgba(0, 0, 0, 0.6); */
}

.liveshowGradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.title-item {
  display: inline-flex;
  padding: 5px 10px;
  background: #f49832;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
}

.genre {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #ffffff;
  display: flex;
  order: 0;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 7px;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-max-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.profile {
  display: flex;
  align-items: center;
}

.profile .avatar {
  width: 34px;
  position: relative;
  padding: 2px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(106.29deg, #f754d3 0%, #f7ac54 101.41%);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 5px;
}

.avatar[data-role='host'] {
  z-index: 4;
}

.avatar[data-role='co-host'] {
  margin-left: -20px;
  z-index: var(--co-host-num);
}

.profile .avatar img {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

/* .avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline;
  margin-right: 5px;
} */

.name {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 22px;
}

.item-header {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.date {
  display: flex;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 40px;
  align-items: center;
}

.date img {
  margin-right: 5px;
}

.date span {
  font-size: 12px;
}

.live-indicator {
  display: flex;
  align-items: center;
}

.red-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
  margin-right: 5px;
}

.live-indicator div:last-child {
  text-shadow: 1px 1px 2px black;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.info-badge {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  width: 40px;
  height: 24px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1000px;
  justify-content: space-around;
  align-items: center;
}

.info-list {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 2;
  align-self: center;
}

.shape {
  display: flex;
  width: 28px;
  height: 13px;
  background: #ffffff;
  border-radius: 50%;
  min-width: 107px;
}

.dot {
  width: 5px;
  height: 3px;
  background: #ffffff;
  border: 3px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
}

@media screen and (max-width: 1500px) {
  .collection-item,
  .empty {
    max-width: 196px;
    width: 15%;
  }
}

@media screen and (max-width: 1335px) {
  .collection-item,
  .empty {
    max-width: 196px;
    width: 19%;
  }
}

@media screen and (max-width: 926px) {
  .collection-item,
  .empty {
    max-width: 196px;
    width: 24%;
  }
}

@media screen and (max-width: 742px) {
  .collection-item,
  .empty {
    max-width: 196px;
    width: 45%;
  }
}

@media screen and (max-width: 550px) {
  .red-dot {
    width: 5px;
    height: 5px;
  }
  .live-indicator {
    font-size: 12px;
  }
  .info-badge {
    width: initial;
    height: initial;
    padding: 0 5px;
    min-width: 25px;
  }
  .info-badge > img {
    margin-right: 2px;
  }
  .item-header {
    padding: 5px;
  }
  .item-header img {
    width: 12px;
  }
  .item-header span {
    font-size: 11px;
  }
  .title-item {
    padding: 1px 5px;
  }
  .genre {
    line-height: initial;
    font: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .title {
    font-size: 11px;
    line-height: initial;
  }
  .profile .avatar {
    width: 24px;
    height: 24px;
  }
  .profile .avatar img {
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 11px;
    line-height: initial;
  }
}

@media screen and (max-width: 328px) {
  .collection-item,
  .empty {
    padding: 5px 0 5px 0;
    width: 45%;
    height: 100%;
    margin: 15px 5px !important;
  }
}
