.tooltip {
  position: relative;
}

.content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  white-space: nowrap;
  border-radius: 4px;
  padding: 6px 10px;
  bottom: 90%;
  left: 50%;
  margin-bottom: 10px;
  z-index: 1;
  animation: fade 3s;
}

.content:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.6);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
