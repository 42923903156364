.formWrapper,
.formContact {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 550px) {
  .formWrapper,
  .formContact {
    align-items: center;
  }
}

.formWrapper {
  margin: 4rem 5rem 2rem;
}

.inputError {
  position: absolute;
  bottom: -25px;
  text-align: start;
}

.formContact {
  margin: 2rem 5rem 10rem;
}

.buttonSave {
  border-radius: 6px;
  height: 50px;
  position: relative;
  top: 7px;
  right: -10px;
}

.formInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.inputForm {
  display: flex;
  flex-direction: inherit;
  width: 100%;
  position: relative;
  margin-bottom: 0.9rem !important;
}

.phoneForm {
  display: flex;
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}

.wrapperPhoneInput {
  display: flex;
  flex-direction: row;
  max-height: 85px;
  max-width: 67%;
  width: 40%;
  min-width: 19em;
  align-items: center;
  margin-top: -5px;
}

.phoneInput {
  display: flex;
  flex-direction: row;
  max-height: 85px;
  align-items: center;
  width: 100%;
}

div.phoneInput > div {
  position: relative;
  width: 100% !important;
}

div.phoneInput > div > input {
  background: var(--dark-color);
  color: rgba(255, 255, 255);
  margin-bottom: 0;
  padding: 13px 13px 13px 3rem;
  width: 100%;
  max-height: 2.5rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

div.phoneInput > div > span[data-addon] {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: min-content;
  left: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.phoneInput input[type='number']::-webkit-outer-spin-button,
.phoneInput input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phoneInput input[type='number'] {
  -moz-appearance: textfield;
}

div.phoneInput button {
  background: var(--dark-color);
  color: #fff;
  align-self: flex-end;
  width: max-content;
  margin-top: 1rem;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  border-radius: 6px;
  border-radius: 10px;
  border-style: unset;
}

.formInput div:last-child {
  display: flex;
  width: 40%;
  min-width: 4em;
  min-width: 300px;
}

.formInput div[data-password-hide]:last-child {
  align-items: center;
}

.formInput input {
  background: var(--dark-color);
  color: rgba(255, 255, 255);
  margin-bottom: -1rem;
}

.formInput input:focus {
  border: 1px solid #f49832;
}

.formInput textarea {
  background: var(--dark-color);
  border-radius: 8px;
  color: rgb(255, 255, 255);
  padding: 1rem;
  height: 4em;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  border: 0;
}

.formInput button {
  background: var(--dark-color);
  border-radius: 6px;
  color: rgba(244, 152, 50, 1);
  padding: 1em;
  border-radius: 10px;
  border-style: unset;
}

.formInput textarea:focus {
  border: 1px solid #f49832;
  outline: auto 0;
}

.inputLabel {
  flex-basis: 15%;
  padding-top: 10px;
  min-width: 7em;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.inputBio {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.profileAction {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profileAction span {
  font-size: 12px;
  line-height: 16px;
}

.profileAction button {
  background: var(--dark-color);
  border-radius: 6px;
  color: rgba(244, 152, 50, 1);
  padding: 1em;
  border-radius: 10px;
  border-style: unset;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #f49832;
  border-radius: 60px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}

.columns {
  display: flex;
  flex-direction: column;
}

.email {
  border-radius: 6px;
  padding: 15px;
  margin: 0 0 16px;
  background: var(--dark-color);
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  width: 100%;
  margin-bottom: -1rem;
  box-sizing: border-box;
  color: #ffffff;
}

.password {
  border-radius: 6px;
  padding: 10px 15px;
  margin: 0 0 16px;
  background: var(--dark-color);
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  width: 100%;
  margin-bottom: -1rem;
  box-sizing: border-box;
  color: #ffffff;
}

.uploader {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 2rem;
}

.formButton {
  background: var(--dark-color);
  color: var(--primary-color);
}

.passwordEdit {
  background: url('../../assets/edit.svg') no-repeat center;
  width: 1.5em;
  height: 1.5em;
  position: relative;
  padding-left: 2em;
  cursor: pointer;
}

.emailHelper {
  margin-top: 2em;
  font-size: 12px;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-color);
}
