/* css for report / block layout  */
.popup-menu {
  position: absolute;
  right: 1rem;
  top: 40px;
  padding: 1rem;
  width: 5rem;

  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
  z-index: 2000;
  background: #1c1c1c;
  border-radius: 10px;
}
.popupMenuWrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.87rem;
  line-height: 1.37rem;
  cursor: pointer;
}
.popupMenuWrapper:last-child {
  margin-top: 20px;
}

.reportContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reportTitle {
  width: 59px;
  height: 24px;
  left: calc(50% - 59px / 2 - 0px);
  top: 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #f49832;
  margin-bottom: 1rem;
}

.reportSubTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 1rem;
}

.optionList {
  width: 100%;
  margin-bottom: 1rem;
  color: #ffffff;
}

.reportOptionsWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.reportOptionsWrapper img {
  margin-right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.reportOptionsWrapper > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f9f9f9;
}

.reportButton {
  position: static;
  width: 258px;
  height: 48px;
  left: 0px;
  top: 0px;
  background: #f20303;
  border-radius: 100px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #ffffff;
}

.cancelButton {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #ffffff;
}

.reportThanksTitle {
  height: 24px;
  left: calc(50% - 59px / 2 - 0px);
  top: 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #f49832;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.reportThanksSubTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 2rem;
  width: 260px;
  text-align: center;
}

.closeButton {
  position: static;
  width: 258px;
  height: 48px;
  left: 0px;
  top: 0px;
  background: #f49832;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #ffffff;
}

.blockTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #f20303;
  margin-bottom: 1rem;
}

.blockSubTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
  width: 268px;
}
.sharelink-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 200px;
  background: rgba(46, 46, 46, 0.4);
  position: absolute;
  top: 47px;
  left: -60%;
  z-index: 3;
  padding: 5px;
  padding-top: 8px;
}
