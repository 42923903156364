.notif-slider-wrapper .slick-dots {
  top: 11em;
  right: 5em;
}

.notif-slider-wrapper {
  min-height: 13rem;
}

.notif-slider-wrapper .slick-slide {
  margin: -10px 5px;
}
