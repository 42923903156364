.form-wrapper {
  margin: 2rem 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.form input {
  position: relative;
  padding: 24px 16px;
  background-color: var(--dark-color);
  color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
}

.form input[id='phone'] {
  padding-left: 3.5rem;
}

.form input[id='otp'] {
  letter-spacing: 10px;
}

.default-code-number {
  position: absolute;
  left: 16px;
  margin: 24px 0;
  font-size: 14px;
}
.addon-code-number {
  position: absolute;
  left: -5px;
  margin: 24px 0;
  font-size: 14px;
}

.form input[id='phone'] ~ .input-placeholder {
  left: 3.5rem;
}

.form .input-placeholder {
  position: absolute;
  left: 16px;
  margin-top: 5px;
  font-size: 10px;
  color: #747474;
  visibility: hidden;
}

.form input::placeholder {
  letter-spacing: normal;
}

.form input:focus {
  border: 1px solid var(--primary-color);
}

.form input:focus::placeholder {
  color: transparent;
}

.form input:focus ~ .input-placeholder {
  visibility: visible;
}

.form input::-webkit-outer-spin-button,
.form input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.verification {
  display: block;
  color: var(--gray-light-text-color);
  font-size: 14px;
  text-align: center;
}

.verification-number {
  display: block;
  margin-bottom: 2rem;
  text-align: center;
}

.resend-otp {
  display: block;
  margin: 3rem 0;
  color: var(--gray-light-text-color);
  font-size: 14px;
  text-align: center;
}

.countdown,
.resend-button {
  color: var(--primary-color);
}

.resend-button {
  cursor: pointer;
  user-select: none;
}

::-ms-reveal {
  filter: invert(100%);
}

.remind {
  color: var(--primary-color);
  margin: auto auto 1rem;
  cursor: pointer;
}

.remind span {
  font-size: 14px;
}

.remind[data-reset] {
  margin-bottom: 2rem;
}
