.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  overflow: auto;
}

.modalWrapper::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  position: absolute;
}

.modalWrapper::-webkit-scrollbar-thumb {
  background: #282828;
  border: 1px solid #282828;
  border-radius: 4px;
}

.modalBox {
  width: 90%;
  max-width: 600px;
  margin: auto;
  background-color: #171717;
  border-radius: 5px;
}

.modalHeader {
  padding: 0 2rem;
}

.modalBanner {
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  background: linear-gradient(93.87deg, #de5dfe 8.94%, #2b32e6 159.23%);
}

.modalContent {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
}

.modalContent ol {
  padding-inline-start: 20px;
}

.modalContent ol > div {
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.labelSection {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.streamKeyBox section div p {
  margin: 0;
  width: min-content;
  white-space: nowrap;
}

.streamKeyBox section div span {
  font-size: 14px;
  color: #f49832;
  cursor: pointer;
}

.boldText {
  font-weight: bold;
  color: #f49832;
}

.editInput {
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.inputSection {
  display: flex;
}

.editInput {
  padding-right: 40px;
}

.inputSection:last-of-type > img {
  width: 20px;
  margin-left: -30px;
  cursor: pointer;
}

.modalContent button {
  align-self: flex-end;
}

@media screen and (max-width: 400px) {
  .modalContent {
    padding: 0 0.5rem;
  }

  .labelSection {
    flex-direction: column;
  }
}
