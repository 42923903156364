.commentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0rem 1rem 0rem 0rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .commentWrapper {
    padding: 0;
  }
}

.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #575859;
  border-left: 1px solid #575859;
}

.title {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  margin: 0rem 1rem 1rem 1rem;
}

.commentItem {
  display: flex;
}

.commentItem div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.commentItem div span {
  margin-right: 0.5rem;
}

.commentItem div span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.commentItem div span:last-child {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
}

.commentAvatar {
  width: 30px;
  height: 30px;
  border-radius: 20%;
  margin-right: 1rem;
}

.commentList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.commentList li {
  list-style-type: none;
  margin-bottom: 1rem;
}

.commentList li > :first-child {
  margin-top: auto !important;
}

.commentListWrapper {
  overflow: auto;
  border-left: 1px solid #575859;
  padding-left: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.overflow {
  flex-direction: column-reverse !important;
}

.commentListWrapper::-webkit-scrollbar {
  width: 12px;
}

.commentListWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.commentListWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px grey;
  box-shadow: inset 0 0 6px grey;
}
