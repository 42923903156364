.ModalWrapper {
  position: absolute;
  min-width: 300px;
  max-height: 256px;
  bottom: 15%;
  right: 80px;
  display: flex;
  z-index: 20;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  /* background-color      : red; */
  border-radius: 10px;
  padding: 24px;
  flex-direction: column;
}

.MenuWrapper {
  width: 100%;
  background: transparent;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.resetButton {
  margin-left: auto;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 100px;
  width: 86px;
  height: 24px;
  background: transparent;
  color: #ffffff;
}

.rightArrow {
  cursor: pointer;
}

.subMenu {
  display: flex;
  flex-direction: column;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
  width: 100%;
}

.subMenuHeader {
  margin-bottom: 16px;
  cursor: pointer;
}

.divider {
  width: 80%;
  height: 0.5px;
  border: 1px solid #2e2e2e;
  margin-bottom: 16px;
}

.subMenuItemWrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

.subMenuItem {
  margin-bottom: 16px;
  cursor: pointer;
}

.subItemSelected {
  color: #f49832;
}
