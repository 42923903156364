.headerSpace {
  height: 88px;
}

.header {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 67px;
  padding: 0 5rem;
  align-items: center;
  background-color: var(--secondary-color);
  box-sizing: border-box;
  z-index: 10;
}

.bottomBar {
  position: fixed;
  display: flex;
  width: 100vw;
  bottom: 0;
  align-items: center;
  background-color: var(--secondary-color);
  box-sizing: border-box;
  z-index: 10;
}

.menuIcon {
  float: left;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-block: 1vh;
  margin-left: 0px;
  width: 25%;
  font-size: 14px;
}

.menuIcon div a {
  display: block;
}
.menuIcon div.selected a {
  color: #f49832;
}

.IconImage {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(1deg);
}

.menuIcon > img {
  height: 2.5vh;
  padding-block: 0.5vh;
}

.menu {
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}
.menu li {
  margin-right: 30px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}
.menu li a {
  height: 100%;
  display: flex;
  align-items: center;
}
.menu li.selected {
  border-bottom: 2px solid var(--primary-color);
}
.menu li span {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menu li span:hover {
  color: var(--primary-color);
}
.menu li span > img[data-dropdown] {
  margin-left: 8px;
  width: 5px;
  transform: rotate(-90deg);
}
.sub-menu-wrapper {
  display: flex;
  padding: 8px 12px;
}
.actions-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actions-menu li {
  margin-left: 20px;
  /* cursor: pointer; */
}
.search {
  position: relative;
  width: 40%;
  max-width: 450px;
}
.search.search-opened:hover {
  opacity: 1;
}
.profile-menu-wrapper {
  display: none;
}
.profile-menu-wrapper.open {
  display: block;
}
.header > img {
  vertical-align: middle;
}
.side-menu-toggle {
  cursor: pointer;
  margin-right: 10px;
}
.side-menu-toggle:hover {
  transition: 0.3s;
  opacity: 0.7;
}
.search-form {
  position: relative;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 53px;
}
.search-media-icon {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  background: url('../../assets/ic-search.svg') no-repeat center;
  opacity: 0.7;
}
.search-media-field {
  background: var(--dark-color);
  color: #fff;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  margin: 0;
  padding-left: 50px;
}

.error {
  border: 1px solid red;
}

.search-button-modal {
  display: none;
}
.user-profile-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.user-profile-icon[data-initial-name] {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.go-live-button {
  display: flex;
  position: relative;
}
.go-live-button p {
  margin: 0;
  margin-left: 5px;
}
.streamer-dashboard-header {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.streamer-dashboard-header img {
  margin: 10px;
}
.button-inactive {
  opacity: 0.3;
  cursor: default;
}
.streaming-button {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  border-radius: 25px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.streaming-button[data-stream-status='true'] {
  background: #f20303;
}
.streaming-button[data-stream-status='false'] {
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
}
:global(.expand-rtl-enter-done) .search-media-field {
  width: 290px;
}
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  z-index: 10;
}
.endStreamModal {
  background-color: #171717;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  border-radius: 5px;
  box-sizing: border-box;
}
.endStreamModal p {
  flex: 100%;
  text-align: center;
}
.endStreamModal > button {
  flex: 30%;
  margin: 10px;
  box-sizing: border-box;
}

.popup-menu {
  padding: 0.3rem 1.5rem;
  width: 11rem;
  /* height: 200px; */
  /* background: #121212; */
}

.popup-menu > div {
  display: flex;
  cursor: pointer;
}

.popup-menu > div img {
  margin-right: 10px;
}

.createButton {
  margin: 0;
  font-size: 16px;
}

.menu-mobile {
  position: fixed;
  top: 67px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--secondary-color);
}

.menu-mobile li {
  padding: 1rem;
}

.menu-mobile li.selected a {
  color: var(--primary-color);
}

.subscribe-button-mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .search {
    display: none;
  }
  .search-button-modal {
    display: block;
  }
}

@media screen and (max-width: 1050px) {
  .header {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 15px;
  }

  .go-live-button {
    display: none;
  }

  .subscribe-button {
    display: none;
  }

  .subscribe-button-mobile {
    display: flex;
  }

  .streamer-dashboard-header {
    display: none;
  }

  .createButton > span {
    display: none;
  }

  .createButton > img {
    margin: 0 !important;
  }

  .user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .user-profile-icon {
    margin: 0;
  }
}
