.detailWrapper {
  margin: 20px 0;
  padding: 2rem 3rem;
  background-color: #171717;
  border-radius: 5px;
  flex-wrap: wrap;
}

.streamPreview,
.gameCategory {
  display: flex;
  justify-content: space-between;
}

.streamPreview > div {
  flex: 1;
}

.streamPreview > div:first-of-type {
  margin-right: 3rem;
}

.headerItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
}

.headerItem h3,
.headerItem p {
  margin: 0;
}

.headerItem h3 {
  margin-right: 20px;
  white-space: nowrap;
}

.headerItem p {
  margin-bottom: 0px;
  margin-right: 20px;
  color: #f49832;
}

.liveGameURL {
  display: flex;
}

.liveGameURL > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.copyButton {
  margin-bottom: 0px;
}

.screenPreview {
  /* width: 100%; */
  /* min-width: 300px; */
  aspect-ratio: 16/9;
  background-color: black;
  position: relative;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.screenPreview > span {
  top: 10px;
  right: 10px;
  position: absolute;
  padding: 0 10px;
  font-size: 12px;
  box-sizing: border-box;
  background-color: #3a3a3a;
  border-radius: 10px;
}

.screenPreview > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
}

.screenPreview > div p {
  margin: 5px;
  font-size: 14px;
}

.loading {
  margin: 20px 0;
  width: 20px;
  height: 20px;
  border: 4px solid #fff;
  border-radius: 50%;
}

.setupButton {
  width: min-content;
  margin: auto;
  color: #f49832;
  white-space: nowrap;
  cursor: pointer;
}

.editButton {
  cursor: pointer;
  background-color: #2e2e2e;
  color: #f49832;
  border-radius: 25px;
  margin: 0;
}

.headerItem p img {
  margin-right: 5px;
}

.streamInfo {
  display: flex;
}

.streamInfo > div:first-of-type {
  flex: 2;
}

.streamInfo > div:last-of-type {
  flex: 1;
}

.streamInfo div p:nth-child(1) {
  color: grey;
  font-size: 14px;
  margin-block-start: 0%;
  margin-block-end: 0%;
}

.streamInfo div p:nth-child(2) {
  word-wrap: break-word;
}

.gameCategory > div {
  flex: 1;
}

.thumbnailPreview {
  aspect-ratio: 3/4;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.editInput {
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 10px 15px;
  width: 40%;
  margin-bottom: 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.inputSection {
  width: 40%;
  display: flex;
  margin-right: 10px;
}

.inputSection .editInput {
  width: 100%;
  height: 100%;
  padding-right: 40px;
}

.inputSection > img {
  margin-left: -30px;
  width: 20px;
  background-color: #3a3a3a;
  cursor: pointer;
}

.detailWrapper > section > div {
  display: flex;
}

.detailWrapper > section > div > .editInput {
  margin-right: 10px;
}

.detailWrapper > section div button {
  margin: 0;
  margin-right: 10px;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  z-index: 99;
}

.modalContainer {
  background-color: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
}

.modalButton {
  width: 100px;
  padding: 10px;
  background-color: #f49832;
  border-radius: 20px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .detailWrapper {
    flex-direction: column;
  }

  .streamPreview > div:first-of-type {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .detailWrapper > section > div {
    flex-direction: column;
  }

  .detailWrapper > section div button {
    margin: 10px 0 0;
  }

  .editInput,
  .inputSection {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .headerItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .liveGameURL > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-break: anywhere;
  }
}

@media screen and (max-width: 400px) {
  .detailWrapper {
    padding: 1rem;
  }

  .streamInfo {
    flex-direction: column;
  }
}
