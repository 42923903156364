.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.modal {
  /* background: #000; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.center {
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
