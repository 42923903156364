.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 250px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100px;
}

.title > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.action > span {
  margin-top: 0.5rem;
  background-color: orange;
  color: white;
  padding: 0.5rem 3rem;
  border-radius: 20px;
  width: 100px;
}
