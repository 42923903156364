:root {
  --captionSize: 28px;
  --captionBgTransparant: 0%;
  --captionColor: 'white';
  --captionEdgesTextShadow: 0px 2px 2px rgba(0, 0, 0, 0.64);
  --captionLineHeight: 42px;
}
.shaka-text-container span.shaka-text-wrapper > span,
.shaka-text-container span.shaka-text-wrapper > span > span > span > span,
.shaka-text-container
  span.shaka-text-wrapper
  > span
  > span
  > span
  > span
  > span,
.shaka-text-container
  span.shaka-text-wrapper
  > span
  > span
  > span
  > span
  > span
  > span {
  color: var(--captionColor) !important;
  font-size: var(--captionSize) !important;
  background-color: rgba(0, 0, 0, var(--captionBgTransparant)) !important;
  line-height: var(--captionLineHeight) !important;
  text-shadow: var(--captionEdgesTextShadow) !important;
}

.shaka-text-container div > span {
  color: var(--captionColor) !important;
  font-size: var(--captionSize) !important;
  background-color: rgba(0, 0, 0, var(--captionBgTransparant)) !important;
  line-height: var(--captionLineHeight) !important;
  text-shadow: var(--captionEdgesTextShadow) !important;
}

@media only screen and (max-width: 600px) {
  .caption-mobile {
    display: none;
  }
}

video {
  height: 360px;
  width: 640px;
  background-color: black;
}

#ad-container {
  /* height: 360px;
  width: 640px; */
  position: absolute;
  top: 0;
}

.shaka-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
