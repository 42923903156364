.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  overflow: auto;
}

.modalBox {
  background-color: #171717;
  margin: auto;
  width: auto;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.modalHeader h3 {
  margin: 0;
  font-size: 15px;
}

.modalHeader img {
  cursor: pointer;
  margin-left: 25px;
}

.editInput {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 8px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.copyButton {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 40%;
}

.shareSection {
  margin-bottom: 20px;
}

.shareSection p {
  margin: 0;
}

.shareItems {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.shareItems div {
  width: 59px;
  height: 59px;
  cursor: pointer;
}

.shareItem {
  margin-right: 1vw;
  margin-left: 1vw;
}

.shareUrl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #131313;
  border-radius: 5px;
  box-sizing: border-box;
}

.shareUrl div {
  width: 100%;
}

.shareUrl div p {
  margin: 0;
}

.shareUrl div p:first-of-type {
  font-size: 12px;
}

.shareUrl div p:last-of-type {
  font-size: 14px;
  color: #f49832;
  word-break: break-all;
  width: 90%;
}

.shareUrl img {
  cursor: pointer;
}

.videolink {
  background-color: #131313;
  color: #f49832;
  font-size: 14px;
  padding: 0;
  border: none;
  width: 100%;
  height: min-content;
  resize: none;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  word-break: break-all;
}
