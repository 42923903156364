.spinner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner-ring {
  text-align: center;
}

.spinner {
  animation: spinnerLoader 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes spinnerLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
