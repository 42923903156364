.sendGiftContainer {
  background: #121212;
  padding: 1rem 1.5rem;
  position: relative;
}

.balanceContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.balanceContent {
  display: flex;
  align-items: center;
}

.diamond {
  margin-right: 6px;
}

.balance {
  margin-right: 16px;
  color: #f9f9f9 !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.topup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #f49832;
  border-radius: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  white-space: nowrap;
}

.giftList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.giftList > img {
  cursor: pointer;
}

.giftList > img[alt='prev'] {
  margin-right: 1.5rem;
}

.giftList > img[alt='next'] {
  margin-left: 1.5rem;
}

.giftSendModalContainer {
  position: absolute;
  min-width: 300px;
  height: 124px;
  left: 25%;
  top: -120px;
  background: #1c1c1c;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  z-index: 1;
}

.giftSendModalContainer .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.giftSendModalContainer .giftAmounts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  background: #2e2e2e;
  border-radius: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #f9f9f9;
  margin-right: 6px;
  cursor: pointer;
}

.giftSendModalContainer .active {
  background: #f49832 !important;
}

.giftSendModalContainer .sendGiftButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  position: static;
  width: 49px;
  height: 24px;
  left: 260px;
  top: 16px;

  /* color/Orange */

  background: #f49832;
  border-radius: 16px;
  margin-left: auto;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.gifts {
  display: flex;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.gifts::-webkit-scrollbar {
  display: none;
}

.listGifts {
  margin: 8px;
  padding: 18px;
  background: rgba(28, 28, 28, 1);
  border-radius: 4.54299px;
  /* border: 0px solid #2e2e2e; */
}

.listGifts:hover {
  border-color: #f49832;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.actionButton {
  margin: 5px 0;
  width: 80%;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 50px;
}

.actionButton[data-btn-status='active'] {
  background-color: #f49832;
}

.actionButton[data-btn-status='inactive'] {
  color: #545454;
  background-color: #2e2e2e;
  cursor: default;
}

.primaryButton {
  background-color: #f49832;
}

.confirmButton {
  background-color: #148d00;
}

.confirmModal {
  padding: 20px;
  width: 25vw;
  min-width: 300px;
  text-align: center;
}

.confirmModal > h3 {
  color: #f49832;
}

.successIcon {
  margin-bottom: 20px;
  border-radius: 50%;
}

.topUpHeader {
  color: #f49832;
}

.selectUserModal {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 400px;
  background-color: #0b0b0b;
}

.selectUserModal > img[alt='close'] {
  margin-left: auto;
  width: 15px;
  cursor: pointer;
}

.selectUserModal > img[alt='gift'] {
  margin-top: 1.5rem;
  width: 52px;
}

.selectUserModal > span {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.selectUserModal > span > img[alt='diamond'] {
  margin-right: 8px;
}

.packName {
  color: var(--primary-color);
}

.liveUserList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 8px;
  width: 100%;
}

.liveUser {
  background-color: #121212;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}

.liveUser[data-selected='true'] {
  border: 1px solid var(--primary-color);
}

.liveUser[data-role='host'][data-odd-list='true'] {
  grid-column-start: 1;
  grid-column-end: 3;
}

.liveUser > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

.liveUser > span {
  font-size: 10px;
}

.liveUser[data-role='host'] > span {
  color: var(--primary-color);
}

.liveUser[data-role='co-host'] > span {
  color: #747474;
}

.selectUserModal > p {
  margin-top: 1.5rem;
  margin-right: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.selectUserModal .loading {
  text-align: center;
  margin: auto;
}

.successAnimation {
  position: relative;
  margin: 3rem 0;
  width: 100px;
  height: 100px;
  padding: 10px;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.successAnimation svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 60%;
  height: 60%;
}

.successAnimation svg path {
  fill: none;
  stroke: #fff;
  stroke-width: 10px;
  animation: check-mark 1s;
  -webkit-animation: check-mark 1s;
}

.circleAnimation {
  width: 100%;
  height: 100%;
  background-color: #ffc000;
  border-radius: 50%;
  animation: scale-up-center 0.7s cubic-bezier(0.855, 1.65, 0.625, 0.68);
  -webkit-animation: scale-up-center 0.7s cubic-bezier(0.855, 1.65, 0.625, 0.68);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes check-mark {
  30% {
    stroke-dasharray: 0 132;
  }
  100% {
    stroke-dasharray: 132 132;
  }
}
@keyframes check-mark {
  30% {
    stroke-dasharray: 0 132;
  }
  100% {
    stroke-dasharray: 132 132;
  }
}

@media screen and (max-width: 1024px) {
  .giftingSection {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 720px) {
  .sendGiftContainer {
    padding: 0.5rem 1rem;
  }

  .giftList > img[alt='prev'] {
    margin-right: 1rem;
  }

  .giftList > img[alt='next'] {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .giftSendModalContainer {
    min-width: 100px;
  }
}
