.episode-item-wrapper {
  height: auto;
  width: auto;
  min-width: 306px;
  max-width: 380px;
  margin: 0 25px 35px 0;
  flex: 0 1 calc(1 / 3 * 100% - 25px); /* flex-basis: 33.33%, margin-right 25px */
  color: #fff;
  cursor: pointer;
}

.episode-thumbnail {
  max-height: 220px;
  aspect-ratio: 16/9;
  border-radius: 10px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.episode-thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.thumbnail-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.32) 30.23%,
    rgba(0, 0, 0, 0.88) 100%
  );
}

.episode-title {
  line-height: 25px;
  margin-bottom: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #f9f9f9;
}

.episode-duration {
  margin: 0 0 10px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #c1c1c1;
}

.episode-description {
  font-weight: normal;
  font-size: 12.5px;
  line-height: 22px;
}

@media screen and (max-width: 1024px) {
  .episode-item-wrapper {
    margin: 0 15px 25px 0;
    flex: 0 1 calc(1 / 3 * 100% - 15px);
  }
}

@media screen and (max-width: 802px) {
  .episode-item-wrapper {
    min-width: 200px;
    flex: 0 1 calc(1 / 2 * 100% - 15px);
  }
}

@media screen and (max-width: 486px) {
  .episode-item-wrapper {
    flex: 0 1 calc(100% - 15px);
  }
}
