.phoneModal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.phoneModal.show {
  display: flex;
}

.passWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0000;
  margin: auto;
  /* padding: 20px; */
  border: none;
  width: 95%;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  min-height: 400px;
  background: var(--dark-modal-color);
  padding: 2rem 3em;
  border-radius: 10px;
  box-sizing: border-box;
}

.form-wrapper > h3 {
  color: var(--primary-color);
}

.form-wrapper > span.verification,
.form-wrapper > span.verification-number {
  display: block;
  margin-bottom: 1rem;
  color: #fff !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form input {
  position: relative;
  padding: 0;
  background-color: var(--dark-color);
  color: #fff;
  border-radius: 6px;
  box-sizing: content-box;
  height: 54px;
  flex: 1;
  font-size: 18px;
  text-align: center;
  margin: 0;
  max-width: 45px;
  background-color: #2e2e2e;
}

.form input[id='phone'] {
  padding-left: 3.5rem;
}

.form input[id='phone'] ~ .input-placeholder {
  left: 3.5rem;
}

.form > input ~ input {
  margin-left: 1rem;
}

.form .input-placeholder {
  position: absolute;
  left: 16px;
  margin-top: 5px;
  font-size: 10px;
  color: #747474;
  visibility: hidden;
}

.form input::placeholder {
  letter-spacing: normal;
}

.form input:focus {
  box-sizing: border-box;
  border: 1px solid var(--primary-color);
}

.form input:focus::placeholder {
  color: transparent;
}

.form input:focus ~ .input-placeholder {
  visibility: visible;
}

.form input::-webkit-outer-spin-button,
.form input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.resend-otp {
  display: block;
  margin: 1rem 0 2rem;
  color: var(--gray-light-text-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.countdown,
.resend-button {
  color: var(--primary-color);
}

.resend-button {
  cursor: pointer;
  user-select: none;
}

::-ms-reveal {
  filter: invert(100%);
}

.actionButton {
  margin: 0;
  padding: 1rem;
  font-size: 16px;
  font-weight: 700;
}

.actionButton[data-submit] {
  margin-bottom: 10px;
}

@media screen and (max-width: 400px) {
  .form-wrapper {
    padding: 1rem;
  }

  .form > input ~ input {
    margin-left: 2px;
  }
}
