.passModal {
  visibility: hidden;
  display: flex;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.passModal.show {
  visibility: visible;
}

.passWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0000;
  margin: 15% auto;
  padding: 20px;
  border: none;
  width: 80%;
}

.passContent {
  display: flex;
  flex-direction: column;
  background: black;
  padding: 2em;
  border-radius: 10px;
  border: 1px solid #575859;
}

.passContent h3 {
  margin-block-start: 0em;
  font-weight: 600;
}

.passContent hr {
  width: 100%;
  border: 1px solid #575859;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.passContent section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 3em;
  width: 22em;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

input.pwd {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  line-height: 19px;
  border: none;
  margin-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
}

input.pwd:focus {
  border: 1px solid #f49832;
}
.passContent section button {
  color: #ffff;
  font-weight: bold;
  padding: 0.5rem;
  border-style: unset;
}

.passContent section span {
  flex-basis: 100%;
  padding-bottom: 0.3rem;
  font-size: 16px;
  font-weight: 700;
}

.passContent section input {
  flex-basis: 100%;
  color: #ffff;
}

.passHelper {
  font-size: 14px;
  line-height: 0%;
  padding: 1rem 0 2rem 0;
  font-weight: 400;
}

.passClose {
  position: relative;
  color: #575859;
  font-weight: bolder;
  float: right;
  right: -147px;
  cursor: pointer;
}

.passWarning {
  font-size: 12px;
  width: 18rem;
  color: orange;
}

.inputGroup {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
}

.showPasswordBtn {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: #ffffff;
  font-weight: bold;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
  cursor: pointer;
  transition: none !important;
  transform: none !important;
}

.buttonAction {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.showPasswordBtn img {
  height: 20px;
  width: 20px;
}

.forgot {
  font-size: 12px;
  margin-top: -1em;
  float: left;
  width: 100%;
}

.forgotText {
  font-weight: 400 !important;
  font-size: 12px !important;
}
