.container,
.header,
.loading-wrapper,
.footer {
  display: flex;
  align-items: center;
}

.footerWrapper {
  text-align: center;
  padding-block: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
}

.scrollable {
  max-height: 60vh;
  min-width: 60vw;
  overflow-y: scroll;
}

.remind {
  color: var(--primary-color);
  margin: 0.5rem;
  cursor: pointer;
}

.remind span {
  font-size: 14px;
}

.remind[data-reset] {
  margin-bottom: 2rem;
}

.scrollable > div > div {
  margin: 1rem 0rem;
}

.container-tnc {
  width: 80vw;
  min-height: 500px;
  padding: 0 1rem;
  flex-direction: column;
  box-sizing: border-box;
}

.footerWrapper > span {
  color: var(--primary-color);
  cursor: pointer;
}

.container {
  width: 90vw;
  max-width: 400px;
  min-height: 400px;
  padding: 0 1rem;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
}

.close-btn {
  background: none;
  position: absolute;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  right: 0px;
  top: 10px;
  transform: translateY(-50%);
  margin-left: 20px;
  padding: 5px;
}

.containerDefault {
  min-height: initial;
  position: relative;
  width: initial;
}

.containerDefault h2 {
  margin: 0px;
  font-size: 1rem;
}

.header {
  width: 100%;
}

.header-tnc {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.header-tnc > img {
  cursor: pointer;
}

.header-tnc > img[alt='close'] {
  width: 24px;
  margin-right: 5rem;
}

.header > h1 {
  width: 100%;
  margin: 0;
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
}

.container > h2 {
  color: var(--primary-color);
}

.header > img {
  cursor: pointer;
}

.header > img[alt='close'] {
  width: 24px;
}

.options {
  width: 100%;
  margin: 3rem 0;
}

.loading-wrapper {
  flex-direction: column;
  margin: 3rem 0;
}

.footer {
  flex-direction: column;
  justify-content: center;
}

.inline-list .state-toggle {
  margin-left: 10px;
}

.state-toggle {
  color: var(--primary-color);
  cursor: pointer;
}

.buttonWrapper {
  width: 100%;
}

.buttonWrapper > p {
  font-size: 10px;
}

.buttonWrapperDefault {
  padding-top: 3rem;
  width: 100%;
}

.buttonWrapper > button > span {
  font-size: 16px;
  padding: 10px;
}
