.title {
  text-align: center;
  font-size: 30px;
}
.form-wrapper {
  display: flex;
  align-items: stretch;
  margin-bottom: 15px;
}
.form-wrapper input {
  margin: 0 5px 0 0;
  height: 45px;
  width: 265px;
}
.form-wrapper button {
  margin-left: 5px;
  height: 45px;
  width: 135px !important;
}
.voucherImg {
  margin-top: 60px;
}
.redeemSuccess {
  color: #23aa0d;
}
.redeemError {
  color: #ea4335;
}

@media screen and (max-width: 768px) {
  .form-wrapper {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 500px) {
  .form-wrapper {
    flex-direction: column;
  }
  .form-wrapper input {
    margin: 0 0 5px 0;
    width: 265px;
  }
  .form-wrapper button {
    margin: 5px 0 0 0;
    width: 265px !important;
  }
}
