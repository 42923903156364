.carousel-wrapper {
  position: relative;
  overflow: hidden;
}

.carousel-wrapper > img {
  position: absolute;
  background-size: cover;
  filter: blur(64px);
  -webkit-filter: blur(64px);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.carousel-wrapper:focus {
  outline: none;
}

.detail-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3rem 5rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.32) 53.43%,
    rgba(0, 0, 0, 0.72) 100%
  );
  z-index: 1;
  box-sizing: border-box;
}

.detail-wrapper[data-direction='ltr'] {
  flex-direction: row;
}

.detail-wrapper[data-direction='rtl'] {
  flex-direction: row-reverse;
}

.detail-wrapper[data-no-banner='true'] {
  background: transparent;
  padding: 1rem 5rem 3rem;
}

.item-thumbnail,
.detail-wrapper[data-direction='rtl'] .detail {
  padding-left: 1rem;
}

.detail,
.detail-wrapper[data-direction='rtl'] .item-thumbnail {
  padding-left: 0;
}

.detail {
  display: flex;
  flex: 1;
}

.item-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-detail .title {
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  margin: 1rem 0;
  cursor: pointer;
}

.item-detail .title-banner-middle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  color: #ffffff;
  margin-bottom: 17px;
}

.item-detail .content-details {
  display: flex;
  color: #fff;
  margin: 0.5rem 0;
}

.item-detail .content-details > span {
  padding-right: 1rem;
  color: #fff;
  line-height: 14px;
}

.item-detail .content-details > span + span {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  padding-left: 1rem;
}

.item-detail .content-details > div {
  display: flex;
}

.item-detail .content-details > div > img {
  margin-right: 10px;
}

.item-detail .content-details > div ~ div {
  margin-left: 2rem;
}

.item-detail .rating {
  font-size: 11px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 4px;
}

.rating-wrapper {
  margin-bottom: 10px;
}

.item-detail .description {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.content-genres {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.content-genres > div {
  padding: 3px 10px;
  font-size: 12px;
  background-color: var(--dark-color);
  border-radius: 25px;
}

.content-genres > div ~ div {
  margin-left: 10px;
}

.profile {
  display: flex;
  align-items: center;
  margin: 0;
}

.avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.content-actions {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.watch,
.item-info {
  width: min-content;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;
  display: flex;
  margin-right: 1rem;
  cursor: pointer;
}

.button-icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.watch .button-icon {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

.item-info .button-icon {
  background-color: #fff;
}

.button-icon img {
  vertical-align: middle;
}

.item-thumbnail {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.item-thumbnail > img {
  width: 100%;
  height: 100%;
  margin-left: auto;
  aspect-ratio: 16/9;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .item-thumbnail {
    display: flex;
  }

  .item-thumbnail > img {
    width: 100%;
    height: initial;
    margin: auto;
  }

  .item-thumbnail-middle > img {
    width: 100%;
    height: initial;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  /* .content-actions {
    margin-block:2rem;
  } */
}

@media screen and (max-width: 950px) {
  .carousel-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .carousel-item {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 20px !important;
  }

  .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: -10px;
    left: 0;
    border-radius: 20px !important;

    /*background: rgba(46, 46, 46, 0.8);*/

    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
  .carousel-item.only {
    cursor: pointer;
  }
  .carousel-item.only:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }

  .carousel-item-campaign {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 20px !important;
  }
  .carousel-item-campaign:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: -10px;
    left: 0;
    border-radius: 20px !important;
  }

  .rating-wrapper {
    margin-bottom: 0px;
  }
  .content {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: left;
    color: white;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
  }

  .content-home-card {
    transform: scale(1.4);
    position: absolute;
    left: 50px;
    bottom: 0;
    text-align: left;
    color: white;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
  }

  .slick-dots {
    display: block !important;
    position: absolute !important;
    left: 0px !important;
    bottom: -20px !important;
    width: 100%;
  }

  .carousel-wrapper > img {
    position: absolute;
    background-size: cover;
    filter: blur(64px);
    -webkit-filter: blur(64px);
    opacity: 0.5;
    width: 100%;
    z-index: -1;
  }

  .carousel-wrapper:focus {
    outline: none;
  }
  .slick-next {
    right: 10px;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 70px 0 !important;
  }

  .slick-slide .content {
    display: block;
    padding: 10px;
  }

  .carousel-container {
    padding-bottom: 25px;
  }

  .upcoming-live-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .upcoming-live-item {
    display: inline-block;
    margin: 10px;
  }

  .upcoming-live-item-detail {
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
  }

  .upcoming-live-carousel-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 20px !important;
    background: #2e2e2e;
  }

  .upcoming-live-carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
  }

  .upcoming-live-carousel-item {
    width: 100% !important;
    padding-top: 56.25% !important;
    position: relative !important;
  }

  .watch {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .title {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
  }

  .description {
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
  }
  .description > span {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .item-thumbnail > img {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .detail-wrapper[data-direction='rtl'] .detail,
  .detail-wrapper[data-direction='rtl'] .item-thumbnail {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 1) 90%
    );
  }

  .seeMoreButton {
    display: none;
  }

  .carousel-item {
    width: 100% !important;
    padding-top: 56.25% !important;
    position: relative !important;
  }

  .upcoming-live .detail {
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 !important;
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-slide .content {
    display: block;
    padding: 10px;
  }

  .watch {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 14px !important;
  }

  .title {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .description {
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
  }

  .upcoming-live .detail {
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 350px) {
  .content-actions {
    flex-direction: column !important;
    justify-content: center;
  }

  .item-detail {
    margin-inline: -1rem;
  }
}

/**/
