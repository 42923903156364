.info-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 15px 0 15px;
}
.premium-info {
  width: 350px;
  margin-left: 120px;
}
.info-title {
  margin: 0 0 1rem;
}
.upgrade {
  margin: 2rem 0 0;
  font-weight: bold;
  font-size: 16px;
  color: #f49832;
}
.package {
  margin: 0.5rem 0 1rem;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f9f9f9;
}
.profit {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin: 3px 0;
}
.profit:before {
  content: '\2713';
  color: #23aa0d;
  margin-right: 5px;
}

@media screen and (max-width: 800px) {
  .premium-info {
    margin-top: 2rem;
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
}
