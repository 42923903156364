.plan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-logo {
  width: 40%;
  margin-bottom: 18px;
}
.plan-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;

  width: 321px;

  background: #1c1c1c;
  border-radius: 10px;
}
.plan-info p {
  margin: 0px 0px 8px 0px;
}
.plan-name {
  text-align: center;
  margin: 0px 0px 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  /* Subtitle/Medium (16\20) */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* text/primary-body */

  color: #f9f9f9;

  border-radius: 6px;
}
.plan-price {
  color: #f49832;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  border-radius: 6px;
}
.plan-duration {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: px;

  color: #dadada;

  border-radius: 6px;
}
.plan-info button {
  border: none;
  outline: none;
  cursor: pointer;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #f9f9f9;

  width: 258px;
  height: 48px;
  margin-top: 8px;

  background: #17c247;
  border-radius: 6px;
}
.plan-info button:hover {
  opacity: 0.9;
}
.plan-info button.popular {
  background-color: #23aa0d;
}
.plan-info button:disabled {
  background-color: #5c5c5c;
  color: #747474;
  pointer-events: none;
}
.popular {
  color: #23aa0d;
}
.plan-description {
  color: #f49832;
  font-size: 14px;
  line-height: 19px;
}
.package-item strong {
  margin-right: 5px;
}
.package-item a[data-url='inactive'] span {
  color: #ececec;
}
