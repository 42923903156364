.recent-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.recent-item {
  width: 160px;
  height: 220px;
  object-fit: cover;
  border-radius: 10px;
}
.centered {
  position: absolute;
  left: 80px;
  transform: scale(1.15);
}

@media screen and (max-width: 365px) {
  .recent-item {
    width: 130px;
  }
  .recent-item.centered {
    left: 65px;
  }
}
