.title {
  text-align: center;
  font-size: 30px;
  z-index: 1;
}

.package-list {
  justify-content: center;
}

.list-wrapper {
  margin-top: 5rem;
}

.list-wrapper:first-child {
  margin-top: 40px;
}

.list-plan {
  /* 3 item per rows */
  width: 100%;
  display: flex;
  z-index: 1;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .list-plan {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 950px) {
  .list-plan {
    width: 85vw;
    overflow: scroll;
  }
  .list-plan > :only-child {
    width: 100%;
  }
}
