.notification-wrapper {
  display: flex;
  flex-direction: column;
  width: 375px;
  background: #121212;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
}

.no-notification {
  width: 100%;
  text-align: center;
}

.no-notification-desc {
  width: 100%;
  text-align: center;
  color: #747474;
}

.category {
  font-size: 0.8rem;
  background: #2e2e2e;
  border-radius: 25px;
  padding: 0.2rem;
  padding-inline: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
  text-align: center;
  max-width: fit-content;
}

.header {
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.content {
  font-size: 0.9rem;
  margin: 0.5rem 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #c1c1c1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slider {
  padding: 1rem;
}

.card {
  width: 100%;
  height: 150px;
  border-radius: 20px;
  background: #1c1c1c;
  padding: 1rem 0rem;
  margin-block: 1rem;
}

.cardDialog {
  width: 100%;
  height: 120px;
  border-radius: 20px;
  background: #1c1c1c;
  padding: 1rem 0rem;
  margin-block: 1rem;
}

.slick-dots {
  display: block;
  position: absolute;
  left: 76px;
  bottom: 60px;
  width: initial;
}

.seeAllButton {
  width: 100%;
  font-weight: 700;
  color: #f49832;
  padding: 1rem 0;
  text-align: center;
  cursor: pointer;
}

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 5px;
}
.slick-dots li button:before {
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  line-height: 12px;
  font-size: 8px;
  opacity: 1;
  color: transparent;
}

.slick-dots li button:after {
  content: ' ';
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.slick-dots li.slick-active button:after {
  background-color: var(--primary-color);
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color);
  opacity: 1;
}

.notifBackground {
  background: #171717;
  padding: 0.5rem 0.5rem;
}

.pageMargin {
  margin-inline: 5rem;
}

.no-notification {
  width: 100%;
  text-align: center;
}

.scrollable {
  height: 50vh;
  width: 100%;
  background: #171717;
  overflow-y: scroll;
  overflow-x: hidden;
}

.read {
  border-left: 1px solid #f49832;
  background: #181820;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timestamp {
  font-weight: 400;
  font-size: 10px;
  color: #c1c1c1;
  padding: 1rem;
}

.title {
  width: 100%;
  font-weight: 700;
  text-align: center;
  padding-block: 1rem;
}

.titleWrapper {
  width: 100%;
  font-weight: 700;
  color: #f49832;
  padding: 1rem;
}

.notifSlider {
  padding: 0 30px;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .notifBackgroundPage {
    margin-inline: 0px !important;
  }

  .pageMargin {
    margin-inline: 0px !important;
  }
}
