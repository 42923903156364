:root {
  --main-color: #f49832;
  --popup-width: 1024px;
  --popup-height: 180px;
}

.backdrop {
  background: #2e2e2e;
}

.wrapper {
  z-index: 10;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 30px;
  width: var(--popup-width);
  height: var(--popup-height);
  align-items: center;
  text-align: center;
  position: absolute;
  top: calc(50% - (var(--popup-height)));
  left: calc(50% - (var(--popup-width) / 2));
}
.header {
  font-weight: 600;
  font-size: 32px;
  line-height: 22px;
  padding-block: 32px;
}

.counter {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  padding: 24px;
}

.action {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.closeButton {
  z-index: 10;
  width: 3rem;
  height: 3rem;
  position: absolute;
  border-radius: 25px;
  top: 5%;
  left: 95%;
}

.questionwrapper {
  z-index: 10;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 30px;
  width: var(--popup-width);
  height: var(--popup-height);
  align-items: center;
  text-align: center;
  position: absolute;
  top: calc(35% - (var(--popup-height)));
  left: calc(50% - (var(--popup-width) / 2));
}

.questionheader {
  font-weight: 600;
  font-size: 32px;
  line-height: 22px;
  padding: 24px;
}

.questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 54px;
}

.actionButton {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 420px) {
  .header {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding-block: 32px;
  }

  .questions {
    display: flex;
    flex-direction: column;
    padding: 54px;
  }
  .actionButton {
    flex-direction: column;
  }

  .questionwrapper {
    left: calc(137% - (var(--popup-width) / 2));
    max-width: 400px;
  }
  .closeButton {
    left: 85%;
  }

  .wrapper {
    width: 100vw;

    left: 1%;
  }
}
