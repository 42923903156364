.empty {
  height: 0 !important;
  min-height: 0 !important;
}

.thumbnail {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-item {
  position: relative;
  margin: 0.5rem;
  min-width: 180px;
  min-height: 250px;
  max-width: 180px;
  aspect-ratio: 3 / 4;
  float: left;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px -6px #000;
}
.slider-item:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.3s;
}
:global(.lazy-slider-item-img) {
  border-radius: 10px;
}
.progress-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 28px;
  opacity: 0.6;
}

.progress-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.progress-detail > div {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #fff;
  border-radius: 50%;
}
.progress-detail > div img {
  height: 10px;
  width: 10px;
}
.progress-detail > span {
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}
.progress-bar {
  width: 100%;
  height: 5px;
  background-color: rgba(84, 84, 84, 0.6);
  border-radius: 5px;
  overflow: auto;
}

.progress-bar::-webkit-scrollbar {
  display: none;
}

.exclusiveBadge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
  z-index: 10;
}

@media screen and (max-width: 750px) {
  .slider-item {
    position: relative;
    margin: 0.5rem;
    width: 30%;
    min-width: 130px !important;
    min-height: 180px !important;
  }
}

@media screen and (max-width: 520px) {
  .slider-item {
    position: relative;
    margin: 10px;
    width: 25%;
    min-width: 100px !important;
    min-height: 150px !important;
    aspect-ratio: 3 / 4;
    float: left;
    border-radius: 10px;
    overflow: visible;
  }

  .thumbnail {
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-height: 150px;
    aspect-ratio: 3/4;
    object-fit: cover;
  }
}

@media screen and (max-width: 400px) {
  .slider-item {
    position: relative;
    margin: 5px;
    width: 28%;
    min-width: 80px !important;
    min-height: 120px !important;
  }
}

@media screen and (max-width: 305px) {
  .slider-item {
    position: relative;
    margin: 5px;
    width: 22%;
    min-width: 50px !important;
    min-height: 70px !important;
  }
}
