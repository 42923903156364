.inputForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 2.5rem;
}

@media (max-width: 1150px) {
  .insideLabel {
    display: none;
  }
}

.optionListSmall > div {
  padding: 0 1rem !important;
}

@media screen and (max-width: 600px) {
  /* Adjust 600px to match your mobile breakpoint */
  .optionListSmall {
    top: -128px !important;
    width: 60% !important;
    left: 30vw !important;
    background: #515151;
    border-radius: 18px !important;
    padding: 0 0 !important;
    color: #f5f5f5;
  }
  .optionListSmall > div {
    padding: 0 1rem;
    max-height: 40vh !important;
  }
}

.inputFormInline {
  width: 100%;
  position: relative;
  margin-inline: 0.5rem;
}

.inputForm > label {
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 1rem;
}

.description {
  margin-top: 1rem;
}

.inputForm input,
.inputForm > .selectInput {
  background: var(--dark-color);
  padding: 13px 18px;
  margin: 0;
  min-height: 48px;
  color: var(--light-input-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border: none;
  outline: none;
  border-radius: 8px;
}

.inputForm input[data-error='true'],
.inputForm > .selectInput[data-error='true'] {
  border: 1px solid var(--danger-color);
}

.inputForm input[type='number']::-webkit-outer-spin-button,
.inputForm input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputForm input[type='number'] {
  -moz-appearance: textfield;
}

/* .inputForm input[data-addon='true'] {
  padding-left: 4rem;
} */

.inputWrapper {
  border-radius: 8px;
  overflow: hidden;
}

.inputForm .inputWrapper {
  position: relative;
  display: flex;
}

.inputForm span[data-addon] {
  position: absolute;
  margin: auto;
  height: min-content;
  font-size: 14px;
  line-height: 22px;
}

.inputForm span[data-addon='start'] {
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  display: flex;
}

.inputForm span[data-addon='end'] {
  top: 0;
  bottom: 0;
  right: 18px;
}

.inputForm > span[data-error='true'] {
  margin-top: 8px;
  color: var(--danger-color);
  font-size: 14px;
  line-height: 22px;
}

.selectInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}

.selectInput[data-active='true'] {
  border-radius: 8px 8px 0 0;
}

.selectInput > span[data-selected='false'] {
  color: var(--gray-light-text-color);
}

.selectInput > img[alt='dropdown'] {
  width: 6px;
  transform: rotate(-90deg);
}

.selectInput,
.optionList {
  user-select: none;
}

.optionInput {
  display: none;
}

.optionList {
  position: absolute;
  top: 84px;
  left: 0;
  width: 100%;
  padding: 1.5rem 0;
  background-color: var(--dark-color);
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  z-index: 10;
}

.checkmark-icon {
  width: 20px;
  height: 20px;
}

.hidden {
  visibility: hidden;
}

.optionList > div {
  padding: 0 2.5rem;
  max-height: 400px;
  overflow: auto;
}

.optionList > div::-webkit-scrollbar {
  width: 30px;
  height: 8px;
  position: absolute;
}

.optionList > div::-webkit-scrollbar-track {
  background: transparent;
}

.optionList > div::-webkit-scrollbar-thumb {
  background: #545454;
  border-radius: 50px;
  background-clip: content-box;
  border: 10px solid transparent;
}

.optionItem {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  box-sizing: border-box;
  cursor: pointer;
}

.optionItem[data-nopadding] {
  padding: 0;
}

.optionItem ~ .optionItem {
  border-top: 1px solid #232323;
}

.optionItem:hover {
  opacity: 0.6;
}

.optionItem > img {
  margin-right: 1rem;
}

.optionItem > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2e2e;
  opacity: 0.8;
}
