.item-wrapper {
  width: 115px;
  position: relative;
  max-width: 120px;
  max-height: 120px;
  min-width: 110px;
  min-height: 110px;
  margin: 10px auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  border-radius: 50%;
  box-sizing: border-box;
  aspect-ratio: 1/1;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .item-wrapper {
    max-width: 75px;
    max-height: 75px;
    min-width: 75px;
    min-height: 75px;
    margin: 10px 5px;
  }
}

.item-wrapper div {
  padding: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
}

.item-wrapper div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.item-wrapper div div {
  background-color: #fff;
}
