.shownUp {
  bottom: 100px;
}

.shownDown {
  bottom: 0px;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.upperSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0000;
  margin: auto;
  /* padding: 20px; */
  border: none;
  /* width: 80%; */
}

.bottomSection {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0000;
  margin: auto;
  /* padding: 20px; */
  border: none;
  /* width: 80%; */
}

.bottomPosition {
  bottom: 0;
}
.topPosition {
  top: 0;
}
.upperBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #121212;
  width: 100%;
  padding: 1em;
  border-radius: 10px;
  box-sizing: border-box;
  z-index: 2000;
}

.button {
  margin-bottom: 0px;
}

.promoButton {
  background: var(--dark-color);
  color: var(--primary-color);
  border: 1px solid #f49832;
}

.ctaBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #121212b3;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  z-index: 2000;
}

.modalContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  z-index: 2000;
}

.timerBar {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 10px;
  background: #121212b3;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.contentText {
  margin-right: 10px;
}

.contentTextBold {
  font-weight: 800;
}

@media screen and (max-width: 460px) {
  .modalContent {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  @media screen and (orientation: portrait) {
    .bottomPosition {
      bottom: -70px;
    }
    .timerBar {
      top: -45px;
    }
  }
  @media screen and (orientation: landscape) {
  }
}
