.errorWrapper {
  text-align: center;
  font-size: 18px;
  height: 300px;
}

.errorWrapper p {
  line-height: 300px;
}

.player-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
