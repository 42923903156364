.slick-track {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .slick-track {
    padding: 0;
  }
}
.slick-slide .content {
  display: none;
}

.content {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  color: white;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.content.big {
  max-width: 100%;
}

.content.big .watch {
  font-size: 22px;
  line-height: 27px;
}

.content.big .title {
  font-size: 30px;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.content.big .description {
  font-size: 15px;
  line-height: 21px;
}

.slick-center {
  transform: scale(1.4);
  transition: 0.5s;
  z-index: 1;
  filter: none !important;
}

.slick-center .content {
  display: block !important;
}

.slick-center .carousel-item {
  border-radius: 20px !important;
  cursor: pointer;
}
.carousel-item {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  background: rgba(46, 46, 46, 0.8);
}
.carousel-item.only {
  cursor: pointer;
}
.carousel-item.only:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.slick-center .carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.carousel-wrapper-container {
  height: 30rem;
  position: relative;
}

.carousel-wrapper-container > img {
  position: absolute;
  background-size: cover;
  filter: blur(64px);
  -webkit-filter: blur(64px);
  opacity: 0.5;
  width: 100%;
  z-index: -1;
  height: 25rem;
}

.carousel-wrapper-container:focus {
  outline: none;
}

.detail-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.32) 53.43%,
    rgba(0, 0, 0, 0.72) 100%
  );
  z-index: 1;
  box-sizing: border-box;
}

.detail,
.item-thumbnail {
  flex: 1;
  padding: 3rem 5rem;
}

.detail {
  padding-right: 1rem;
  display: flex;
}

.item-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1rem;
}

.item-detail .title {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  margin: 1rem 0 2rem;
  cursor: pointer;
}

.item-detail .content-details {
  display: flex;
  color: #fff;
}

.item-detail .content-details > span {
  padding-right: 1rem;
  color: #fff;
  line-height: 14px;
}

.item-detail .content-details > span + span {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  padding-left: 1rem;
}

.item-detail .rating {
  font-size: 11px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 4px;
}

.item-detail .description {
  margin: 2rem 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.content-actions {
  display: flex;
  flex-wrap: wrap;
}

.watch,
.item-info {
  width: min-content;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;
  display: flex;
  margin-right: 1rem;
  cursor: pointer;
}

.button-icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.watch .button-icon {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

.item-info .button-icon {
  background-color: #fff;
}

.button-icon img {
  vertical-align: middle;
}

.item-thumbnail {
  display: flex;
  padding-left: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.item-thumbnail > img {
  /* width: 100%; */
  /* max-width: 100%; */
  /* min-height: 100%; */
  /* max-height: 100%; */
  height: 100%;
  margin: auto;
  /* margin-left: auto; */
  aspect-ratio: 16/9;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

/* .slick-dots {
  display: block;
  position: absolute;
  left: 20%;
  bottom: 80px;
  width: initial;
}
.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 5px;
}
.slick-dots li button:before {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  line-height: 12px;
  font-size: 8px;
  color: transparent;
}

.slick-dots li button:after {
  content: ' ';
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.slick-dots li.slick-active button:after {
  background-color: var(--primary-color);
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color);
  opacity: 1;
} */

.dot {
  width: 10px;
  height: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
}

.custom-arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.custom-arrow > img {
  width: 14px;
  height: 14px;
}

.slick-next > img {
  transform: rotate(180deg);
}

.slick-next {
  right: 20px;
}

.slick-prev {
  left: 20px;
  z-index: 1;
}

.slick-next::before {
  content: '';
}

.slick-prev::before {
  content: '';
}

.slick-next:hover,
.slick-next:focus,
.slick-prev:hover,
.slick-prev:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

.player-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1350px) {
  .item-thumbnail {
    display: flex;
  }

  .item-thumbnail > img {
    width: 100%;
    height: initial;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .item-detail .description {
    margin: 0rem 0;
  }
}

@media screen and (max-width: 950px) {
  .carousel-wrapper-container {
    height: fit-content;
  }
  .detail-wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1024px) {
    .item-detail .description {
      margin: 0rem 0;
    }
  }

  .item-detail .title {
    margin: 1rem 0;
    font-size: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .detail,
  .item-thumbnail {
    flex: none;
    padding: 1rem 5rem;
    box-sizing: border-box;
  }
  .item-thumbnail {
    height: 20rem;
    justify-content: center;
  }
  .item-thumbnail > img {
    width: initial;
    height: 100%;
    margin: auto;
  }

  .slick-dots {
    left: 76px;
    bottom: 20px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  .item-thumbnail > img {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 1) 90%
    );
  }

  .detail-wrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .carousel-item {
    padding-top: 133.33%;
  }

  .item-detail .title {
    margin: 0.5rem 0;
    font-size: 20px;
  }

  .item-detail .description {
    margin: 1.5rem 0;
  }

  .slick-track {
    padding: 0;
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-slide .content {
    display: block;
  }

  .carousel-wrapper-container {
    height: fit-content;
  }
  .detail,
  .item-thumbnail {
    flex: none;
    padding: 1rem 2rem;
  }
  .item-thumbnail {
    height: 15rem;
  }
  .item-thumbnail > img {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .slick-dots {
    left: 30px;
    bottom: 20px;
  }
  .custom-arrow {
    display: none;
  }
}
