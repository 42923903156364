/* .editStreamInfoModal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  padding: 2rem 0;
  box-sizing: border-box;
  z-index: 10;
} */

.editStreamInfoModal::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  position: absolute;
}

.editStreamInfoModal::-webkit-scrollbar-thumb {
  background: #282828;
  border: 1px solid #282828;
  border-radius: 4px;
}

.modalWrapper {
  background-color: #171717;
  width: 90%;
  min-width: 300px;
  max-width: 600px;
  margin: auto;
  padding: 30px;
  border-radius: 5px;
  box-sizing: border-box;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
}

.modalHeader h3 {
  margin: 0;
}

.modalHeader > img {
  cursor: pointer;
}

.modalContent > section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.sectionHeader {
  margin-bottom: 1em;
  font-weight: bold;
}

.sectionHeader p {
  margin: 0;
}

.editInput {
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.editInput:focus {
  outline: none !important;
  border: 1px solid #f49832;
}

.inputError {
  outline: none !important;
  border: 1px solid #ff0000;
}

.errorMessage {
  color: #ff0000;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  font-weight: 400;
}

.descriptionInput {
  font-family: 'Open Sans', sans-serif;
  resize: none;
}

.descriptionInput::-webkit-scrollbar,
.modalContent::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  position: absolute;
}

.descriptionInput::-webkit-scrollbar-thumb,
.modalContent::-webkit-scrollbar-thumb {
  background: #282828;
  border: 1px solid #282828;
  border-radius: 4px;
}

.descriptionCount {
  align-self: flex-end;
  margin: 0;
  color: #6a6a6a;
  font-size: 12px;
}

.categoryItem {
  display: inline-block;
  padding: 2px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #282828;
  color: #fff;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
}

.categoryItem span {
  margin-left: 5px;
  font-size: 14px;
}

.selectedCategory {
  background-color: #f49832;
  color: #fff;
}

.thumbnailSection {
  display: flex;
  flex-wrap: wrap;
}

.thumbnailSection > span p {
  margin: 0;
  font-size: 14px;
  color: #c1c1c1;
  white-space: nowrap;
}

.thumbnailLabel {
  width: min-content;
  margin-right: 10px;
}

.selectThumbnail {
  width: min-content;
  height: 200px;
  aspect-ratio: 3/4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background-color: #2e2e2e;
  color: #c1c1c1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
}

.selectThumbnail img {
  margin-bottom: 20px;
}

.thumbnail {
  width: 200px;
  visibility: hidden;
  display: none;
}

/* .previewThumbnail {
  height: 200px;
  aspect-ratio: 3/4;
  border-radius: 5px;
  cursor: pointer;
} */

.modalAction {
  display: flex;
  justify-content: flex-end;
}

.modalAction button {
  margin-left: 10px;
}

.loading {
  width: 100%;
  height: 100%;
}

.loading p {
  text-align: center;
}
