.listInfoProfile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #f9f9f9;
  flex: none;
}

.listInfoProfile img {
  cursor: pointer;
}

.profileName {
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-right: 24px;
  cursor: pointer;
}

.profileName .name {
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.profileFollower {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-light-text-color);
}

.avatar[data-initial-name] {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.avatar[data-live='true'] {
  padding: 2px;
  background-color: #000;
}

.liveBorder {
  margin-right: 10px;
}

.liveBorder[data-live-active='true'] {
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  padding: 1px;
  width: min-content;
  height: min-content;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.followBtn {
  padding: 3px 18px;
  border-radius: 15px;
  background-color: #f49832;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  text-wrap: nowrap;
}

.endDivider {
  align-self: stretch;
  border-right: 1px solid #2e2e2e;
  margin: 0 1.5rem;
}

.streamBadgeInfo {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 8px;
  border-radius: 25px;
  min-width: 60px;
  background-color: var(--dark-color);
}

.streamBadgeInfo:last-of-type {
  margin-right: 0;
}

.streamBadgeInfo > img {
  width: 20px;
  margin-right: 8px;
}

.badge-wrapper {
  display: flex;
}

@media screen and (max-width: 700px) {
  /* .profileName {
    flex-basis: 75%;
  } */

  .endDivider,
  .streamBadgeInfo {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .profileName .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
