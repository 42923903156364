.topUpGuidelineModal {
  padding: 0;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 350px;
  /* min-width: 400px; */
  /* height: 300px; */
  padding: 32px;
}

.header {
  width: 100%;
  text-align: center;
  /* border-bottom: 1px solid #2e2e2e; */
}

.header > h3 {
  margin-top: 0;
  color: var(--primary-color);
}

.subtitle {
  text-align: center;
  font-size: 14px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer > * {
  margin: 0 10px;
  border: 1px solid #fff;
  border-radius: 10px;
}

.action {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 8px 0;
  background-color: orange;
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border-style: none;
  border-radius: 50px;
  cursor: pointer;
}

/* @media screen and (max-width: 578px) {
  .wrapper {
    width: 90vw;
  }

  .subtitle {
    width: 90vw;
  }

  .action {
    width: 70vw;
  }
} */

@media screen and (max-width: 420px) {
  .footer > a {
    margin-top: 10px;
  }
  .footer > a:last-child {
    margin-bottom: 10px;
  }

  .wrapper {
    height: auto;
  }

  .subtitle {
    width: 80vw;
    margin-top: 15px;
  }

  .footer {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
}
