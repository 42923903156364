.uploadStatusModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 40px;
  margin: auto;
  width: 25%;
  min-width: 350px;
  height: min-content;
  background-color: #171717;
  border-radius: 18px;
  box-sizing: border-box;
}

.uploadStatusHeader[data-upload-status='success'] {
  margin-top: 0;
  color: #f49832;
}

.uploadStatusModal > p {
  margin-bottom: 3rem;
  text-align: center;
}

.actionButton {
  padding: 14px 0;
  width: 100%;
  text-align: center;
  border-radius: 25px;
  cursor: pointer;
}

.actionButton[data-upload-status='success'] {
  background-color: #f49832;
}

.actionButton[data-upload-status='failed'] {
  background-color: #ff3434;
}
