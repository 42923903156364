.commentInput {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  bottom: 0;
}

.emoticonWrapper {
  width: 92%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.commentInput div > input {
  background: #363636;
  margin-bottom: 0px;
  color: #ffffff;
  background: #1c1c1c;
}

.emotToggle {
  background: url('../../assets/smile.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 5px;
}

.commentForm {
  padding: 0.5rem 1rem;
  border-radius: 40px;
  /* background: #363636; */
  width: 92%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
  background: #1c1c1c;
}

.commentForm h4 {
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 41px;
}

.emotInput {
  width: 100%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.emotList {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.emotList span {
  cursor: pointer;
}
