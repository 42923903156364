.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container > svg path {
  fill: none;
  stroke-width: 4;
}

.container > svg path:nth-child(1) {
  stroke: #161616;
}

.container > svg path:nth-child(2) {
  stroke: #f49832;
  stroke-linecap: round;
  stroke-dashoffset: 220 440;
}

.container > svg[data-loading='true'] {
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.percentage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.percentage > p {
  margin: auto;
  font-size: 24px;
  font-weight: bold;
}
