:global(.glider-prev),
:global(.glider-next) {
  background: rgba(0, 0, 0, 0.8);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  color: rgba(255, 255, 255, 0.8);
  font-size: 36px;
  border-radius: 50%;
}
:global(.glider-prev img),
:global(.glider-next img) {
  width: 10px;
}
:global(.glider-prev) {
  left: -30px;
}
:global(.glider-next) {
  right: -30px;
}
:global(.glider-prev:hover),
:global(.glider-next:hover) {
  color: #fff;
}
:global(.glider-next:hover) {
  right: -18.5px;
}
:global(.glider-next img) {
  transform: rotate(180deg);
}

.gliderItem > div {
  margin-top: 0;
  margin-bottom: 0;
}

:global(.glider-contain .small-button) {
  width: 40px !important;
  height: 40px !important;
}

:global(.glider-contain .glider-prev.small-button) {
  left: -50px;
}

:global(.glider-contain .glider-next.small-button) {
  right: -50px;
}

:global(.glider-slide img) {
  width: 100%;
}

:global(.live-tv .glider-slide) {
  min-width: 115px;
}

@media screen and (max-width: 950px) {
  :global(.glider-contain .glider-prev.small-button) {
    left: -20px;
  }
  :global(.glider-contain .glider-next.small-button) {
    right: -20px;
  }
  :global(.glider::-webkit-scrollbar) {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  :global(.glider-prev),
  :global(.glider-next) {
    display: none;
  }
  :global(.live-tv .glider-slide) {
    min-width: 85px;
  }
  .gliderItemTV {
    max-width: 50px;
  }
  :global(.glider-slide) {
    max-width: 120px;
  }
}
