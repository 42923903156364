.wrapper {
  max-width: 1308px;
  margin: 0 auto;
  min-height: calc(100vh - 350px);
}
.title-wrapper {
  display: flex;
  padding: 0 15px;
  margin-top: 10px;
}
.title-wrapper h1 {
  flex: 1;
  margin: 0 0 30px 0;
  font-size: 46px;
}

.content {
  line-height: 1.5;
  padding: 0 15px;
}

.content p {
  margin: 0;
}
