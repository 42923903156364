.modalContainer {
  display: flex;
  flex-direction: column;
}

.modalContainer > .header {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}
