.episode-item-wrapper {
  height: 100%;
  width: auto;
  min-width: 107px;
  max-width: 196px;
  margin: 0 25px 35px 0;
  color: #fff;
  cursor: pointer;
}

.episode-thumbnail {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 3/4;
}

.episode-title {
  font-size: 18px;
  line-height: 25px;
}

.episode-duration {
  margin: 0 0 10px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.episode-description {
  font-weight: normal;
  font-size: 12.5px;
  line-height: 22px;
}

@media screen and (max-width: 1024px) {
  .episode-item-wrapper {
    min-width: initial;
  }
  .episode-thumbnail {
    width: initial;
  }
  .episode-item-wrapper {
    margin: 0 15px 25px 0;
    /* flex: 0 1 calc(1 / 3 * 100% - 15px); */
  }
}

@media screen and (max-width: 802px) {
  /* .episode-item-wrapper {
    min-width: 200px;
    flex: 0 1 calc(1 / 2 * 100% - 15px);
  } */
}

@media screen and (max-width: 486px) {
  /* .episode-item-wrapper {
    flex: 0 1 calc(100% - 15px);
  } */
}

@media screen and (max-width: 380px) {
}
