.profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 260px;
  background: #121212;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  padding: 2rem 1rem 2rem 1rem;
}
.profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #f49832;
  border-radius: 60px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
.profile-wrapper p {
  font-size: 16px;
  line-height: 22px;
  word-break: break-all;
}
.profile-email {
  margin: 0 0 40px 0;
}
.profile-settings p:hover {
  cursor: pointer;
  color: #f49832;
}
.logout {
  cursor: pointer;
}
.logout:hover {
  color: #f49832;
}

.avatarBox {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 3px solid rgb(255, 255, 255, 0.2);
  border-radius: 50%;
}

.avatarBox > img[alt='premium-icon'] {
  padding: 4px;
  width: 18px;
  height: 18px;
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  border-radius: 50%;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #f49832;
  border-radius: 60px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}

.premiumIcon {
  position: absolute;
  bottom: -10px;
}

.packWrapper {
  display: flex;
  background: linear-gradient(93.87deg, #835dfe 8.94%, #5091f0 159.23%);
  border-radius: 10px;
  width: 100%;
}

.packWrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 50%;
  padding: 0.7rem 0;
}

.packWrapper > div:first-child {
  border-right: 2px solid rgb(46, 46, 46, 0.1);
}

.packWrapper > div > span:nth-child(1) {
  min-width: 3rem;
  display: flex;
  justify-content: space-between;
}

.packWrapper > div > span:nth-child(2) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.packWrapper > div > section {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding: 0.25em 1em;
  align-items: center;
  cursor: pointer;
}

.packWrapper > div > span > img {
  margin-right: 5px;
}

.topup {
  background: #f49832;
  white-space: nowrap;
}

.cashout {
  background: #17c247;
  white-space: nowrap;
}

.buttonWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 14rem;
  padding: 2em 2em 0;
}

.buttonWrapper > div {
  display: flex;
  flex-basis: 50%;
  align-items: center;
  justify-content: space-evenly;
  min-width: 5.5em;
  padding: 5px 0;
  cursor: pointer;
}

.buttonWrapper > div:first-of-type {
  border-right: 1px solid rgb(255, 255, 255, 0.2);
}

.logoutAll {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 320px) {
  .profile-wrapper {
    background: rgba(0, 0, 0, 0.8);
    width: calc(100% - 40px);
    padding: 15px 20px;
  }

  .logout {
    margin-bottom: 0;
  }
}
