.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
}

.container p {
  text-align: center;
}

.warning {
  color: #f20303;
}
