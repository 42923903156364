.container {
  width: 90vw;
  max-width: 400px;
  min-height: 400px;
  padding: 0 1rem;
  flex-direction: column;
  box-sizing: border-box;
}

.container > h2 {
  color: var(--primary-color);
  text-align: center;
}

.container > p {
  font-size: 10px;
}

.buttonWrapperDefault {
  width: 100%;
}

.round {
  position: relative;
  display: flex;
  align-items: center;
}

.round label {
  background-color: #2e2e2e;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.checkboxWrapper {
  display: inline-flex;
  min-width: 20px;
}
.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: var(--primary-color);
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.round span {
  position: relative;
  font-size: 10px;
  margin-left: 1.5rem;
  min-width: 200px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.checkbox-container p {
  font-size: 10px;
  white-space: pre-wrap;
  margin-top: 0;
}
