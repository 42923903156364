.fullScreenModeContainer {
  background: transparent;
  width: 100%;
  height: 100%;
  padding-top: 34px;
  padding-left: 16px;
}

.userInfoContainer,
.commentContainer,
.viewBadge {
  position: absolute;
}

.commentContainer {
  height: min-content;
  margin: 0 1rem;
  margin-top: 100px;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.32) 30.23%,
    rgba(0, 0, 0, 0.72) 100%
  );
}

.moreBtn {
  margin: 0;
  margin-right: 1rem;
}

.popup-menu {
  position: absolute !important;
  top: 2rem;
  left: 0;
  padding: 1rem;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
  z-index: 2000;
  background: #1c1c1c;
  border-radius: 10px;
}

.popupMenuWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.87rem;
  line-height: 1.37rem;
  cursor: pointer;
}

.badgeWrapper {
  display: flex;
  align-items: center;
  margin-right: 32px !important;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-style: normal;
  border-radius: 50px;
  padding: 5px 9px;
  min-width: 60px;
  max-height: 24px;
  height: 24px;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff;
  box-sizing: border-box;
  background-color: var(--dark-color);
}

.badge span {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff;
}

.badge[data-live] span {
  font-weight: 600 !important;
}

.badge + .badge {
  margin-left: 1rem;
}

.badge > img {
  margin-right: 7px;
  width: 15px;
}

.badge[data-live-badge] {
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: red;
}

.badge[data-live-badge][data-onhover='true'] {
  transform: translate(0, -40px);
  transition: all 0.5s;
}

.badge[data-live-badge][data-onhover='false'] {
  transform: translate(0);
  transition: all 1s;
}

@media screen and (max-width: 700px) {
  .userInfoContainer,
  .commentContainer {
    display: none;
  }
}
