.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/subscription_page_bg.svg');
}
.contentWrapper {
  z-index: 1;
  text-align: center;
  margin-bottom: 20%;
}
.bottom-gradient {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(44, 44, 44, 0.29) 33.85%,
    #000000 72.4%
  );
}
