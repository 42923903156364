.commentInput {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  bottom: 0;
  /* background: #363636; */
}

.commentInput > img {
  position: absolute;
  width: 100%;
  top: -85px;
}

.emoticonWrapper {
  width: 92%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.commentInput div > input {
  background: transparent;
  margin-bottom: 0px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.emotSmile {
  background: url('../../assets/smile_two.svg');
}

.bulletIcon {
  background: url('../../assets/ic-bullet.svg');
}

.emotToggle {
  background-repeat: no-repeat;
  background-position: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* top: 5px; */
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.bulletActive {
  background-color: var(--primary-color);
}

.bulletInactive {
  background-color: #2e2e2e;
}

.bulletMessage {
  position: absolute;
  top: -120%;
  width: 85%;
  background-color: var(--primary-color);
  margin: 0 0.5rem 2rem;
  padding: 20px 17px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.bulletMessage > span:first-of-type {
  margin-right: 10px;
}

.commentForm {
  padding: 1rem;
  display: flex;
  height: 3rem;
  justify-content: flex-start;
  align-items: center;
}

.inputBox {
  background: #2e2e2e;
  border-radius: 100px;
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 16px;
  position: static;
  width: 100%;
  height: 40px;
  left: 16px;
  top: calc(50% - 40px / 2);
  margin-right: 8px;
}

.loginButton {
  margin-top: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  left: 16px;
  background: var(--primary-color);
  border-radius: 1000px;
  cursor: pointer;
}

.emotInput {
  width: 100%;
  display: flex;
  height: 3rem;
  justify-content: space-evenly;
}

.emotList {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.emotList span {
  cursor: pointer;
}

.emoteModal {
  position: absolute;
  top: -180px;
  right: 0px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 153px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px -3px #000;
  background: #1c1c1c;
  border-radius: 10px;
  padding: 16px 32px;
}

/* .emoteModal:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #222222;
  border-bottom: 10px solid transparent;
  right: 8%;
  bottom: -19px;
} */

.emoteModal > span {
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
