.section-container {
  padding: 1rem 5rem;
}

.section-info {
  display: flex;
  justify-content: space-between;
}

.section-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #f9f9f9;
  width: 500px;
}

@media screen and (max-width: 550px) {
  .section-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 10px;
    color: #f9f9f9;
    width: 500px;
  }

  .see-all {
    margin: 10px;
  }
}

.see-all {
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  color: #f9f9f9;
}

@media screen and (max-width: 965px) {
  .section-container {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .section-container {
    padding: 1rem 1rem;
  }
}
