.media-info-wrapper {
  color: #fff;
}

.general-info-wrapper {
  position: relative;
  min-height: 200px;
}

.gradient-top {
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(57, 57, 57, 0.14) 68.23%,
    rgba(149, 149, 149, 0) 100%
  );
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 53.43%, rgba(0, 0, 0, 0.72) 100%);; */
  top: 0;
}

.thumbnail {
  width: 100%;
  height: 440px;
  object-fit: cover;
}

.poster {
  position: absolute;
  width: 38.75rem;
  height: 348px;
  top: 48px;
  z-index: 1;
  right: 75px;
  bottom: 47px;
  border-radius: 16px;
}

.media-metadata-wrapper {
  /* position: absolute; */
  top: 20%;
  left: 75px;
  z-index: 1;
}

.media-title {
  font-weight: 300;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;

  color: #ffffff;
  margin-bottom: 24px;
}

.media-metadata {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.media-metadata span {
  margin: 0 10px;
}

.media-metadata span:first-child {
  margin-left: 0;
}

.media-metadata > span + span {
  border-left: 2px solid rgba(255, 255, 255, 0.4);
  padding-left: 1rem;
}

.media-metadata .rating {
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 0 6px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.btn-wrapper > img {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.media-metadata-wrapper button {
  position: relative;
  background: #148d00;
  color: #fff;
  border-radius: 1000px;
  padding: 8px 60px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.media-metadata-wrapper button:focus {
  border: 1px solid !important;
}

.media-metadata-wrapper button.not-subscribed-button {
  background: #f49832;
}

.media-metadata-wrapper button[data-loading='false']:before {
  position: absolute;
  top: calc(50% - 7px);
  left: 40px;
  border-radius: 1px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 12px solid #fff;
  content: '';
}

.description {
  font-size: 0.87rem;
  line-height: 1.37rem;
  align-items: center;
  max-width: 100%;
}

.gradient-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.733) 50.43%,
    rgb(0, 0, 0) 100%
  );
  top: 0;
}

.casting {
  color: #ffffff;
}

.casting h3 {
  margin: 15px 0 5px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.casting span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .media-metadata-wrapper {
    left: 45px;
  }

  .poster {
    display: none;
  }
}

@media screen and (max-width: 526px) {
  .media-metadata-wrapper {
    left: 25px;
  }

  .poster {
    display: none;
  }

  .media-title {
    font-size: 23px;
  }
}

.wrapper-btn-circle > img {
  padding-left: 10px;
}

@media screen and (max-width: 300px) {
  .media-title {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 370px) {
  .btn-wrapper {
    align-items: stretch;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .wrapper-btn-circle {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
  }
}

.divider {
  /* width: 1px; */
  height: 14px;
  background-color: #545454;
  border: 1px solid #545454;
  margin: 0px 16px;
}
