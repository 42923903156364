.slick-track {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .slick-track {
    padding: 0;
  }
}

.slick-slide .content {
  display: none;
}

.content {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  color: white;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.content.big {
  max-width: 100%;
}

.content.big .watch {
  font-size: 22px;
  line-height: 27px;
}

.content.big .title {
  font-size: 30px;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.content.big .description {
  font-size: 15px;
  line-height: 21px;
}

.slick-center {
  transform: scale(1.4);
  transition: 0.5s;
  z-index: 1;
  filter: none !important;
}

.slick-center .content {
  display: block !important;
}

.slick-center .carousel-item {
  border-radius: 20px !important;
  cursor: pointer;
}
.carousel-item {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  background: rgba(46, 46, 46, 0.8);
}
.carousel-item.only {
  cursor: pointer;
}
.carousel-item.only:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.slick-center .carousel-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.carousel-wrapper {
  height: 30rem;
  position: relative;
  overflow: hidden;
}

.carousel-wrapper > img {
  position: absolute;
  background-size: cover;
  filter: blur(64px);
  -webkit-filter: blur(64px);
  opacity: 0.5;
  width: 100%;
  z-index: -1;
}

.carousel-wrapper:focus {
  outline: none;
}

.slick-dots {
  display: block;
  position: absolute;
  left: 76px;
  bottom: 60px;
  width: initial;
}

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 5px;
}
.slick-dots li button:before {
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  line-height: 12px;
  font-size: 8px;
  opacity: 1;
  color: transparent;
}

.slick-dots li button:after {
  content: ' ';
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.slick-dots li.slick-active button:after {
  background-color: var(--primary-color);
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color);
  opacity: 1;
}

.dot {
  width: 10px;
  height: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
}

.custom-arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.custom-arrow > img {
  width: 14px;
  height: 14px;
}

.slick-next > img {
  transform: rotate(180deg);
}

.slick-next {
  right: 20px;
}

.slick-prev {
  left: 20px;
  z-index: 1;
}

.slick-next::before {
  content: '';
}

.slick-prev::before {
  content: '';
}

.slick-next:hover,
.slick-next:focus,
.slick-prev:hover,
.slick-prev:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

.player-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  margin: 1rem 5rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #f9f9f9;
}

@media screen and (max-width: 950px) {
  .slick-dots {
    display: block !important;
    position: absolute !important;
    left: 0px !important;
    bottom: -20px !important;
    width: 100%;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 70px 0 !important;
  }

  .carousel-container {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 500px) {
  .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 1px;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(0, 0, 0, 0.55) 50%,
      rgba(0, 0, 0, 1) 90%
    );
  }
  .seeMoreButton {
    display: none;
  }

  .carousel-item {
    width: 100% !important;
    padding-top: 56.25% !important;
    position: relative !important;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 !important;
  }
  .slick-slide .content {
    display: block;
    padding: 10px;
  }

  .slick-dots {
    bottom: 0;
  }
}
