.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  z-index: 10;
}

.clipsModal {
  position: relative;
  top: 10vh;
  padding-bottom: 10vh;
  width: 90%;
  max-width: 1000px;
  height: min-content;
}

.clipsModalContent {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  background-color: #121212;
  border-radius: 20px;
  box-sizing: border-box;
}

.uploadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3rem 3rem;
  text-align: center;
}

.closeButton {
  display: block;
  margin-top: 3rem;
  margin-right: 3rem;
  margin-left: auto;
  width: 20px;
  cursor: pointer;
}

.postClipsButton {
  margin: 0;
  margin-left: auto;
  padding: 11px;
  max-width: 150px;
  text-align: center;
  background-color: #2e2e2e;
  color: #747474;
  font-size: 14px;
  cursor: default !important;
}

/* .postClipsButton[data-button-status='inactive'] {
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  color: #fff;
} */

.postClipsButton[data-button-status='active'] {
  background: linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%);
  color: #fff;
  cursor: pointer !important;
}

.detailContent {
  padding: 0.5rem 2rem 4rem;
  display: flex;
}

.metadataSection {
  flex: 3;
}

.metadataSection > section {
  position: relative;
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
}

.categoryItem {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #1c1c1c;
  color: #fff;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
}

.titleCount {
  position: absolute;
  bottom: 10px;
  right: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #c1c1c1;
}

.categoryItem span {
  font-size: 12px;
  font-weight: 600;
}

.categoryItem[data-selected='active'] {
  background-color: #f49832;
  color: #fff;
}

.editInput {
  margin-bottom: 0;
  padding: 0px 16px 30px;
  width: 100%;
  height: 80px;
  background: #1c1c1c;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
}

.thumbnailSection {
  display: flex;
}

.thumbnailSection > p {
  margin: 0 20px;
  width: 30%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.selectThumbnail {
  width: min-content;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2e2e2e;
  color: #c1c1c1;
  white-space: nowrap;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  aspect-ratio: 16/9;
}

.selectThumbnail img {
  margin-bottom: 15px;
}

.thumbnailInput {
  width: 200px;
  visibility: hidden;
  display: none;
}

.previewThumbnail {
  width: 200px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.thumbnailError {
  color: #ff3434;
  font-size: 14px;
}

.videoPreview {
  flex: 2;
  padding-left: 2rem;
}

.videoPreview > video {
  margin-top: 4rem;
  width: 100%;
  height: min-content;
  border-radius: 10px;
  aspect-ratio: 16/9;
}

.videoPreview > p {
  color: #c1c1c1;
  font-size: 14px;
}

.modalErrorWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  z-index: 11;
}

.modalError {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 50px;
  width: 25%;
  min-width: 350px;
  background-color: #171717;
  border-radius: 18px;
  box-sizing: border-box;
}

.errorHeader {
  margin-top: 0;
  font-weight: bold;
  color: #f49832;
}

.modalError > p {
  text-align: center;
}

.modalError ul {
  margin-bottom: 40px;
  width: 90%;
  list-style: circle;
}

.errorConfirmButton {
  padding: 14px 0;
  width: 100%;
  text-align: center;
  background-color: #ff3434;
  border-radius: 25px;
  cursor: pointer;
}

.settingsError {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #121212;
  border-radius: 10px;
  z-index: 12;
}

.settingsError > div {
  padding: 10px 20px;
  background-color: #f49832;
  border-radius: 50px;
  cursor: pointer;
}

.uploadProgressModal {
  width: 150px;
  height: 150px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.uploadProgressModal p {
  text-align: center;
}

@media screen and (max-width: 700px) {
  .uploadSection {
    margin: 0 1rem 1rem;
  }

  .closeButton {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .detailContent {
    flex-direction: column;
  }

  .thumbnailSection {
    flex-direction: column;
  }

  .thumbnailSection > p {
    margin: 0;
    margin-top: 1rem;
    width: 100%;
  }

  .videoPreview {
    padding: 0;
  }
}

@media screen and (max-width: 700px) {
  .detailHeader,
  .detailContent {
    padding: 0.5rem 1rem;
  }
}
