.collection-item {
  min-width: 200px;
  position: relative;
  float: left;
  display: block;
  cursor: pointer;
  /* margin: 15px auto; */
  margin: 15px 15px 15px 0;
  flex-grow: 1;
}

.collection-item[data-list='playlist'] {
  margin: 15px 10px;
  width: 15%;
  max-width: 242px;
}

.empty {
  height: 0;
  width: 25%;
  max-width: 242px;
  margin: 15px 10px;
}

.collection-item a {
  width: 100%;
  height: 100%;
  display: block;
}

.item-wrapper {
  /* width: 242px; */
  height: 100%;
  /* background-color: #1d1d1d; */
  border-radius: 6px;
  overflow: hidden;
}

.collection-item[data-list='playlist'] .item-wrapper {
  width: 100%;
}

.item-image {
  /* padding-bottom: 60%; */
  width: 100%;
  position: relative;
  border-radius: 10px;
  aspect-ratio: 16/9;
  /* height: 136px; */
}

.item-image:hover {
  opacity: 0.7;
  transition: 0.3s all;
}

.video-duration {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 8px;
  padding: 4px 8px;
  width: min-content;
  color: #ffffff;
  background: rgba(18, 18, 18, 0.8);
  border-radius: 6px;
}

.item-description {
  margin: 8px 0px;
  color: #f9f9f9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.item-description h3 {
  width: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 8px 0px;
  line-height: 1.2em;
  min-height: 2.4em; /* 2 lines × 1.2em line-height */
}

.item-description > span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #c1c1c1;
}

.item-profile {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.item-profile > img {
  width: 30px;
  height: 30px;
  background-color: #e15a33;
  border-radius: 50%;
  object-fit: cover;
}

.item-profile > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e15a33;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.item-profile > span {
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 768px) {
  .collection-item[data-list='playlist'] {
    /* margin: 15px 10px; */
    /* width: 242px; */
    max-width: 90%;
    height: auto;
  }
}
