.wrapper {
  /* max-width: 1308px; */
  /* margin: 0 auto; */
  padding-right: 5rem;
  padding-left: 5rem;
  /* margin: 2.5rem 0 0; */
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-block: 1rem;
  color: #f9f9f9;
}

.title-wrapper p {
  margin: 0;
  cursor: pointer;
}
.collection {
  position: relative;
}
.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* no data result */
.noData {
  margin: 3rem 0;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textNoVideos {
  margin: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #c4c4c4;
}

@media screen and (max-width: 970px) {
  .wrapper {
    padding: 0 2rem 0 2rem;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 0 2rem 0 2rem;
  }
}

@media screen and (max-width: 400px) {
  .wrapper h1 {
    font-weight: bold;
    font-size: 16px;
    padding-left: 0;
  }

  .wrapper {
    padding: 0 10px;
  }
}

@media screen and (max-width: 750px) {
  .textNoVideos {
    margin-top: 50px;
  }
}

@media screen and (max-width: 900px) {
  .wrapper h1 {
    font-size: 30px;
  }
  .wrapper {
    margin-top: 1rem;
  }
}
