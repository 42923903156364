.listWrapper {
  display: flex;
  justify-content: center;
  margin: 2.5rem auto;
  padding: 0 2.5rem;
  min-height: 200px;
  flex-direction: column;
  background: var(--dark-modal-color);
  border-radius: 10px;
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2.5rem 0;
}

.itemWrapper ~ .itemWrapper {
  border-top: 1px solid var(--dark-color);
}

.detail {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
}

.detail > * {
  grid-column: 1/2;
}

.detail[data-clickable] {
  cursor: pointer;
}

.detail[data-clickable]:hover > h3.title {
  color: var(--primary-color);
}

.detail > h3.title {
  margin: 0 0 0.5rem 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--light-input-color);
}

.detail > span.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  word-break: break-all;
  color: var(--gray-light-text-color);
}

.detail > span.date {
  margin-top: 10px;
}

.detail > span.date,
span.date:nth-child(4) {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--grey-input-color);
}

.detail > .amount {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #747474;
  border-radius: 6px;
}

.detail > hr {
  width: 100%;
  border: none;
}

.next-billing-date > span[data-grace-periode='true'] {
  color: var(--danger-color) !important;
}

.warning-message {
  width: 75%;
  display: flex;
  align-items: flex-start;
  background-color: #611414;
  color: #f9f9f9 !important;
  margin-top: 4px;
  padding: 10px;
  font-size: 12px;
  border-radius: 8px;
}

.warning-message > img[alt='ic-danger'] {
  margin-right: 8px;
}

.amount > span:nth-child(1) {
  color: var(--grey-input-color);
}
.amount > span:nth-child(2) {
  color: var(--gray-light-text-color);
}

.action span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-light-text-color);
}

.action span span[data-status='processing'] {
  color: var(--primary-color);
}

.action span span[data-status='completed'] {
  color: var(--success-color);
}

.action span span[data-status='failed'] {
  color: var(--danger-color);
}

.button-wrapper {
  grid-column: 2/3;
  grid-row: 1/10;
}

.renewButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  gap: 16px;

  /* color/Orange */

  background: #f49832;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  width: 164px;
  height: 38px;

  /* background/orange */

  background: #f49832;
  border-radius: 100px;
}

.renewButton > span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.noData > div:nth-child(1) {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* text/primary-body */

  color: #f9f9f9;

  border-radius: 6px;
}

.noData > div:nth-child(2) {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #c1c1c1;

  border-radius: 6px;
}

@media screen and (max-width: 1000px) {
  .warning-message {
    width: initial;
  }
}

@media screen and (max-width: 750px) {
  .listWrapper {
    background-color: transparent;
    padding: 0;
    margin: 1rem auto;
  }

  .itemWrapper {
    background-color: var(--dark-modal-color);
    padding: 1.5rem;
    margin: 0;
    border-radius: 8px;
  }

  .itemWrapper ~ .itemWrapper {
    border: none;
    margin-top: 1rem;
  }

  .detail > h3.title,
  .detail > span.subtitle,
  .detail > hr {
    grid-column: 1/3;
  }

  .detail > hr {
    margin: 1rem 0;
    border: 1px solid #2e2e2e;
  }

  .button-wrapper {
    grid-column: 2/3;
    grid-row: 4/10;
  }

  .renewButton {
    width: initial;
    height: initial;
    padding: 5px 8px;
    font-size: 10px;
  }
}
