.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  margin: 1rem 5rem 0;
  flex-wrap: nowrap;
  overflow: auto;
  overflow: auto;
}

.navigator {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.moreTab {
  position: relative;
  height: min-content;
  display: flex;
  align-items: center;
}

.moreTab img {
  margin-left: 8px;
}

.navigator > li > div > span,
.moreTab span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: pre;
}

.navigator > li > p {
  margin: 0 auto;
}

.navigator > li {
  cursor: pointer;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
}

.navigator li ~ li {
  margin-left: 1.5rem;
}

.navigator > .optionItem {
  cursor: default;
}

.optionItem {
  white-space: nowrap;
}

.verticalLine {
  margin-left: 1.5rem;
  border: 1px solid #545454;
  height: 20px;
}

.navWrapper::-webkit-scrollbar {
  height: 4px !important;
  /* display: none; */
}

.navWrapper::-webkit-scrollbar-track {
  background: var(--grey-input-color);
}

.navWrapper::-webkit-scrollbar-thumb {
  background: var(--primary-color) !important;
  border-radius: 10px !important;
}

.navWrapper::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color) !important;
}

@media screen and (max-width: 1050px) {
  .navWrapper {
    padding: 0;
    margin: 2rem 15px 0;
  }
}

@media screen and (max-width: 900px) {
  .navWrapper {
    padding: 0;
    margin: 2rem 1rem 0;
  }
}

@media screen and (max-width: 570px) {
  .navWrapper {
    padding: 0;
  }
}

@media screen and (max-width: 750px) {
  .rightOption li:last-child {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1220px) {
  .navWrapper {
    overflow: auto;
  }
}

.popup-menu {
  padding: 1rem;
  width: max-content;
  background: #1c1c1c;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.45);
}

.popupMenuWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.87rem;
  line-height: 1.37rem;
  cursor: pointer;
  padding: 0.8rem 1rem;
}

.popupMenuWrapper > span {
  color: #f9f9f9;
}

.popupMenuWrapper:hover span {
  color: var(--primary-color);
}

.activeStyleBottom {
  border: 3px solid var(--primary-color);
  background-color: var(--primary-color);
  margin-top: 0.1rem;
  width: 45%;
  margin: 0.3rem auto;
  /* border: 10px; */
}
