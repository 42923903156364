@media screen and (min-width: 1280px) and (max-width: 1440px) {
  :global(.liveshow-close.vjs-dismiss-btn-wrappers.relative) {
    top: 32% !important;
  }
  .lastJoinedNotif {
    top: 550px !important;
  }
}

:global(.shaka-range-element) {
  height: 100% !important;
  top: 0 !important;
}
:global(.video-js) {
  position: static;
  font-size: 16px;
  color: #979797;
  --main-color: #f49832;
  --secondary-color: #979797;
}

:global(.video-js-relative) {
  position: relative;
}

:global(.live-player .video-js .vjs-progress-control) {
  display: none;
}

:global(.live-player .video-js .vjs-current-time) {
  display: none;
}

:global(.live-player.relative) {
  height: 100% !important;
}

:global(.live-player) {
  position: relative;
  aspect-ratio: 16/9;
}

:global(.live-game-player) {
  position: relative;
  width: 100%;
}

:global(.video-js .vjs-tech) {
  outline: none !important;
}

:global(.video-js .vjs-big-play-button) {
  background-color: var(--main-color);
  border: 3px solid #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: #fff;
  outline: none !important;
}

:global(.vjs-big-play-centered .vjs-big-play-button) {
  margin-top: -1em;
  margin-left: -1em;
}

:global(.video-js:hover .vjs-big-play-button),
:global(.video-js .vjs-big-play-button:focus) {
  background-color: var(--main-color);
}

.setupButton {
  color: #f49832;
  white-space: nowrap;
  cursor: pointer;
  padding: 3%;
}

:global(.video-js .vjs-control) {
  color: #fff;
}

:global(.video-js .vjs-live-display) {
  display: none;
}
:global(.video-js .vjs-control-bar .vjs-button) {
  /* width: 55px; */
  outline: none !important;
}
:global(.video-js .vjs-control-bar) {
  /* background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(0, 0, 0, 0.4) 90%,
    rgba(0, 0, 0, 0.4) 100%
  ); */

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.32) 30.23%,
    rgba(0, 0, 0, 0.88) 100%
  ) !important;
  padding: 0 12px;
  display: flex !important;
}

/* custom svg icon */
:global(.video-js .vjs-control-bar .vjs-custom-icon) {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
:global(.video-js .vjs-progress-control) {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  top: -20px;
  border-radius: 1em;
  transition:
    top 150ms linear,
    opacity 150ms linear,
    transform 150ms linear;
  opacity: 0;
  padding: 0 4px;
}
:global(.video-js:hover .vjs-control-bar .vjs-progress-control) {
  opacity: 1;
  top: -22px;
}
:global(.video-js .vjs-progress-control:hover .vjs-progress-holder) {
  font-size: 25px;
}
/* :global(.vjs-icon-play:before),
:global(.video-js .vjs-play-control .vjs-icon-placeholder:before),
:global(.video-js .vjs-big-play-button .vjs-icon-placeholder:before) {
  content: '\f101';
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
} */
/* end or replay icon */
:global(.vjs-icon-replay:before),
:global(.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before) {
  font-size: 35px;
}

:global(
    .live-player .video-js .vjs-big-play-button .vjs-icon-placeholder:before
  ) {
  position: relative;
}

/* show currentTime / duration */
:global(.video-js .vjs-time-control) {
  padding: 0;
  text-align: left;
  display: block;
}
:global(.video-js .vjs-time-control .vjs-current-time-display) {
  margin-right: 6px;
  padding-left: 4px;
}

:global(.video-js .vjs-fullscreen-control) {
  margin-left: auto !important;
}
:global(.video-js .vjs-time-control.vjs-time-divider) {
  min-width: 10px;
  color: #fff;
}
:global(.video-js .vjs-time-control.vjs-duration) {
  flex: 1;
}

/* hide remaining time */
:global(.video-js .vjs-remaining-time) {
  display: none;
}

/* Slider */
:global(.video-js .vjs-slider-bar.vjs-play-progress) {
  color: var(--main-color);
  background: var(--main-color);
}
:global(.video-js .vjs-load-progress div) {
  background: var(--secondary-color);
}

/*loading*/
:global(.vjs-loading-spinner:before),
:global(.vjs-loading-spinner:after) {
  border-color: var(--main-color);
}

/* menu popup */
:global(.vjs-menu-button-popup .vjs-menu .vjs-menu-content) {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  font-size: 14px;
  z-index: 2;
}

:global(.vjs-menu li.vjs-selected),
:global(.vjs-menu li.vjs-selected:focus),
:global(.vjs-menu li.vjs-selected:hover),
:global(.js-focus-visible .vjs-menu li.vjs-selected:hover) {
  background-color: #f49832;
  color: #fff;
}

/* hide the captions settings item from the captions menu */
:global(.video-js .vjs-texttrack-settings) {
  display: none;
}

:global(.video-js .vjs-subs-caps-button .vjs-icon-placeholder:before) {
  content: '\f10c' !important;
}

:global(.video-js .vjs-resolution-button) {
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.video-js .vjs-resolution-button-label) {
  font-size: 1em;
  line-height: 2.6rem;
  position: absolute;
  top: 2;
  right: 16px;
}

:global(.vjs-dismiss-player-icon) {
  position: absolute;
  top: 17px;
  left: 20px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  cursor: pointer;
  z-index: 10;
}

:global(.vjs-dismiss-btn-wrapper) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 1rem;
  z-index: 2001;
}
:global(.vjs-dismiss-btn-wrapper:hover) {
  visibility: visible !important;
}

:global(.vjs-dismiss-btn-wrappers) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 2.5rem;
}

:global(.vjs-dismiss-btn-wrapper.relative) {
  top: 12% !important;
}

:global(.vjs-dismiss-btn-wrappers.relative) {
  top: 27% !important;
}

:global(.vjs-dismiss-btn-wrapper) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 1rem;
  z-index: 2001;
}

:global(.vjs-dismiss-btn-wrappers) {
  background-color: grey;
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 25px;
  top: 1rem;
  left: 2.5rem;
}

:global(.vjs-dismiss-btn-wrapper.relative) {
  top: 12% !important;
}

:global(.vjs-dismiss-btn-wrappers.relative) {
  top: 27% !important;
}

:global(.vjs-viewer-player-icon) {
  position: absolute;
  top: 17px;
  left: 20px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  z-index: 1;
  background-size: auto;
  background-image: url(../../assets/user.svg);
  background-repeat: no-repeat;
}

:global(.vjs-viewer-btn-wrapper) {
  background-color: #3f3e3e;
  width: 5rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 20px;
  top: 12%;
  right: 28%;
}

:global(.vjs-viewer-btn-wrappers) {
  background-color: #3f3e3e;
  width: 5rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  border-radius: 20px;
  top: 27%;
  right: 28%;
}

:global(.vjs-viewer-btn-wrappers > span) {
  position: absolute;
  top: 0.9rem;
  left: 3rem;
}

:global(.vjs-viewer-btn-wrapper > span) {
  position: absolute;
  top: 0.9rem;
  left: 3rem;
}

.showResumePopup :global(.video-js) {
  opacity: 0.7;
  pointer-events: none;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}
.subscriptionError {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.closeButton {
  width: 100%;
  height: 2rem;
  border-radius: 25px;
  align-items: left;
  display: flex;
  flex-direction: row-reverse;
}

.subscriptionErrorPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1rem;
  background-color: #121212;
  border-radius: 18px;
}

.ErrorPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 1rem;
}
.ErrorPopup > h2 {
  margin-block: 0.5rem;
}
.ErrorPopup > p {
  text-align: center;
}
.subscriptionError > img {
  margin-top: 1rem;
}
.subscriptionError .subtitle {
  margin: 2.5rem 10px;
  text-align: center;
}

.subtitle {
  margin-inline: 1rem;
  text-align: center;
}

.subscribeButton {
  position: relative;
  background: #17c247;
  color: #fff;
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin: 4rem 0 3rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  border: none;
}
.redeemtion {
  color: #f49832;
  cursor: pointer;
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.subscriptionList {
  width: 1020px;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.subscriptionItem {
  background: #148d00;
  color: #fff;
  width: 21%;
  border-radius: 5px;
  margin: 40px 5px 0px 5px;
  padding: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
}
.customMessageWrapper {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 2rem;
  border-radius: 10px;
  border: 1px grey solid;
  max-width: 400px;
}

.customMessageWrapper button {
  padding: 0.5rem;
  color: #ffff;
  background-color: #148d00;
  border-radius: 5px;
  border-style: none;
}

.bulletCommentDisplay {
  display: block;
  width: 500px;
  height: 130px;
  position: relative;
  float: right;
  margin-top: -4rem;
  top: 122px;
  z-index: 1;
}

.bulletContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-radius: 20px;
  font-size: large;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.5);
}

.bulletContainer > span:nth-child(2) {
  margin-left: 0.5rem;
  font-size: medium;
  font-weight: 500;
}

.bulletContainer .bulletMessageName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4da660;
}

.basicGiftDisplay {
  position: relative;
  z-index: 1;
  top: 70px;
  width: 500px;
  text-align: right;
}

.basicWrapper {
  background: orange;
  width: 400px;
  float: right;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  height: 50px;
  margin-bottom: 2rem;
}

.basicWrapper > span {
  margin-left: 1rem;
}

.basicAvatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.basicIcon {
  position: absolute;
  float: right;
  left: 408px;
  width: 150px;
  margin-top: -16px;
}

.basicAmount {
  display: flex;
  width: 23rem;
  font-size: 100px;
  font-weight: 900;
  font-family: sans-serif;
  color: orange;
  position: absolute;
  left: 540px;
  margin-top: -20px;
  flex-wrap: nowrap;
}

.premiumGiftDisplay {
  padding-top: 2rem;
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 74%;
  display: flex;
  justify-content: center;
}

.premiumWrapper > img {
  margin-top: -250px;
}

.premiumInfo {
  background: orange;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 10rem;
  margin-left: 10rem;
  border-radius: 20px;
  padding: 0.5rem;
}

.lastJoinedNotif {
  position: absolute;
  background: #575859;
  z-index: 2;
  top: 750px;
  padding: 0.5rem 3rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity: 0.8;
}

.lastJoinedNotif > span {
  font-size: small;
  text-decoration: solid;
  font-weight: 900;
}

.bulletWrapper {
  display: flex;
  float: right;
  flex-direction: column-reverse;
  width: 80%;
}

.bulletAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bulletCommentData {
  /* color: orange !important; */
  /* padding-left: 1rem; */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.basicGiftWrapper {
  position: absolute;
  top: 280;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  height: 62vh;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
}

.viewerBox {
  display: flex;
  background-color: #3f3e3e;
  margin: 10px;
  padding: 2px 10px;
  position: absolute;
  z-index: 10;
  border-radius: 20px;
  top: 0;
  right: 0;
}

.viewerBox > img {
  margin-right: 5px;
}

.videoLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.videoLoading > p {
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

.chatFullscreenContainer {
  display: inline-block;
  background-color: transparent;
  height: '80%';
  position: absolute;
}

.playerBadges {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playerBadges[data-position='top'] {
  top: 16px;
  right: 16px;
}

.playerBadges[data-position='bottom'] {
  bottom: 16px;
  right: 16px;
}

.playerBadges[data-position='bottom'][data-onhover='true'] {
  transform: translate(0, -40px);
  transition: all 0.5s;
}

.playerBadges[data-position='bottom'][data-onhover='false'] {
  transform: translate(0);
  transition: all 1s;
}

.playerBadges > div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3px 8px;
  border-radius: 25px;
  min-width: 60px;
  background-color: red;
}

.playerBadges > div ~ div {
  margin-left: 10px;
}

.playerBadges > div[data-item='gencash'] {
  background-color: #000000a3;
}

.playerBadges > div[data-item='views'] {
  background-color: #1c1c1c;
}

.playerBadges > div[data-item='live'] {
  background-color: #f20303;
}

.playerBadges > div[data-item='upcoming'] {
  background-color: #fff;
  color: #545454;
}

.playerBadges > div > img {
  margin-right: 5px;
  height: 15px;
}

.hostdata {
  width: 45%;
  margin: 4px;
  max-width: 17%;
  position: absolute;
  justify-content: space-between;
  display: flex;
  font-size: 10px;
  color: #fff;
}

.hostdata[data-fullscreen='true'] {
  max-width: 350px;
}

.hostdata[data-position='0'] {
  right: 50%;
}

.hostdata[data-position='1'] {
  left: 50%;
}
.hostdata[data-position='1'][data-odd='true'] {
  left: initial;
  right: 50%;
  top: 50%;
}

.hostdata[data-position='2'] {
  right: 50%;
  top: 50%;
}
.hostdata[data-position='2'][data-odd='true'] {
  right: initial;
  left: 50%;
  top: 50%;
}

.hostdata[data-position='3'] {
  left: 50%;
  top: 50%;
}

.hostname,
.hostLabel,
.totalGencash {
  margin: 0;
  padding: 3px 8px;
  border-radius: 50px;
  background-color: rgba(18, 18, 18, 0.54);
}

.host {
  display: flex;
  flex-direction: column;
  max-width: 100px;
}

.host div {
  display: flex;
}

.host div img[alt='mute'] {
  width: 14px;
}

.hostLabel {
  margin-top: 4px;
  color: var(--main-color);
  width: min-content;
}

.hostname {
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.totalGencash {
  /* margin-left: 2rem; */
  display: flex;
  align-items: center;
}

.totalGencash > img {
  margin-right: 8px;
}

@media only screen and (max-width: 900px) {
  :global(.live-player[data-multi='true']) {
    aspect-ratio: 16/16;
  }

  .hostdata {
    max-width: 32%;
  }
}

@media only screen and (max-width: 700px) {
  .playerBadges > div {
    min-width: 30px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .basicGiftWrapper {
    width: 100%;
  }

  /* .playerBadges {
    top: 10px;
    right: 20px;
  } */
}

@media only screen and (max-width: 500px) {
  .basicGiftWrapper {
    width: 100%;
  }

  .subscriptionError {
    margin-top: 5rem;
  }

  .subscriptionErrorPopup {
    margin-inline: 1rem;
  }

  /* .playerBadges {
    top: 8px;
    right: 15px;
  } */

  .playerBadges > div {
    min-width: 30px;
    font-size: 10px;
  }
}

.user-profile-icon[data-initial-name] {
  /* background-color: var(--primary-color); */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f49832;
}
