.wrapper {
  margin: 2.5rem 5rem;
  height: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 5%;
}

.section-title {
  position: static;
  color: #f9f9f9;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 51%;
  justify-content: space-between;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

.section-title h4 {
  cursor: pointer;
}

.section-title-active {
  color: #f49832;
}

.section-buy-package-header {
  font-size: 32px;
  margin: 0;
  margin-bottom: 2rem;
}

.section-buy-package {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
}

.section-buy-package h2 {
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f9f9f9;
  display: flex;
  align-items: center;
  text-align: center;
}

.section-buy-package p {
  margin: 8px 0 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #f49832;
  display: flex;
  align-items: center;
  text-align: center;
}

.section-buy-package-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.subs-wrapper {
  margin: 1rem 0;
}

.subs-wrapper > p {
  margin: 0;
}

.subs-wrapper > p.subs-expires {
  color: #dadada;
  font-size: 14px;
}

.separator {
  margin: 2rem 0;
  font-size: 23px;
  border: none;
  border-top: 1px solid #2e2e2e;
  width: 100%;
}

.section-buy-package-container {
  margin: 2rem 0;
  width: 100%;
}

.text {
  text-align: center;
}

.text > img {
  margin-block: 0.5rem;
}

.section-buy-package-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.section-buy-package-divider {
  display: flex;
  flex-basis: 33.33333%;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.wrapper-name {
  position: static;
  width: 280px;
  height: 20px;
  left: 10px;
  top: 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  color: #f9f9f9;

  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.discount {
  font-size: 14px !important;
  color: #f20403 !important;
  text-decoration: line-through;
}

.text-price {
  position: static;
  width: auto;
  height: 28px;
  left: 58px;
  top: 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #f49832;
  border-radius: 6px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 2px 0px;
}

.package-group-heading {
  text-align: center;
}

.text-valid {
  display: block;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #dadada;
  flex: none;
  flex-grow: 0;
}

.text-valid:last-child {
  padding-top: 1rem;
}

.recurring-desc {
  margin-top: 1rem;
}

.recurring-desc > .text-valid:last-child {
  padding: 0;
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  position: static;
  background: #1c1c1c;
  border-radius: 10px;
  order: 1;
  justify-content: space-between;
}

.button {
  position: static;
  width: 258px;
  height: auto;
  left: 14.5px;
  top: 80px;

  background: #17c247;
  border-radius: 6px;
  border: none;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
}

.button-text {
  width: 100%;
  height: 20px;
  left: calc(50% - 74px / 2);
  top: calc(50% - 20px / 2);

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-align: center;

  color: #f9f9f9;
}

.section-top-up-diamonds {
  width: 737px;
  height: 342px;
  background: #121212;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 500px) {
  .section-top-up-diamonds {
    width: 100%;
  }
}

.section-top-up-diamonds-wrapper {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-top-up-diamonds-wrapper h2 {
  position: static;
  left: 0px;
  top: 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #f9f9f9;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
}

.section-top-up-diamonds-wrapper p {
  margin-top: -10px;
  margin-bottom: 10px;
  position: static;
  width: 474px;
  height: 24px;
  left: 23.5px;
  top: 38px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #f9f9f9;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 6px 0px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.icon-img {
  border: white 1px solid;
  border-radius: 10px;
}

.icon-app-store {
  padding-right: 10px;
}

.icon-app-google-play {
  padding-left: 10px;
}

.bottom-desc {
  margin-top: 10rem;
  font-size: 14px;
}

@media screen and (max-width: 750px) {
  .section-title {
    width: 100%;
    font-size: 18px;
    margin-top: 2rem;
  }
  .section-buy-package-container {
    width: 100%;
  }

  .section-title h4 {
    font-size: 1rem;
    line-height: 1em;
  }

  .section-buy-package-header {
    font-size: 24px;
  }

  .section-buy-package h2 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin: 2.5rem 1rem;
  }

  .section-top-up-diamonds-wrapper h2 {
    font-size: 21px !important;
    overflow-wrap: break-word;
    max-width: 100vw;
    padding-block: 1.5rem;
  }

  .section-top-up-diamonds-wrapper p {
    font-size: 14px !important;
    overflow-wrap: break-word;
    max-width: 80vw !important;
  }

  .icon-app-store {
    padding-right: 0 !important;
  }

  .icon-app-google-play {
    padding-left: 0 !important;
    padding-block: 0.5rem;
  }

  .icon {
    flex-direction: column;
  }
}
