.modal {
  visibility: hidden;
  display: flex;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  visibility: visible;
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0000;
  margin: auto;
  /* padding: 20px; */
  border: none;
  /* width: 80%; */
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #121212;
  padding: 2em;
  border-radius: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 460px) {
  .modalContent {
    width: 90vw;
  }
}
