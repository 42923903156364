.button {
  position: relative;
  border: none;
  background: var(--button-props-background);
  color: var(--button-props-color);
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  justify-content: center;
  overflow: hidden;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
}

.button[data-disabled='true'] {
  cursor: default !important;
}

.button-icon {
  padding: 8px;
}

.button-icon img {
  aspect-ratio: 1/1;
}

.outlined {
  border: 1px solid #f49832;
  background-color: transparent !important;
}

.text {
  padding: 8px 0;
  background-color: transparent !important;
}

.default-shape {
  border-radius: 6px;
}

.round-shape {
  border-radius: 50px;
}

.small {
  border-radius: 5px;
  padding: 6px 0;
}

.large {
  margin-right: 2rem;
}

.button > div {
  margin-right: 0.7rem;
}

/* .button > div > img {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
} */

.button[data-disabled='true']:hover {
  transition: initial !important;
  cursor: default !important;
  opacity: initial !important;
  transform: initial !important;
}

.button.darken-hover:hover {
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.7;
  transform: scale(1);
}

.button.scale-hover:hover {
  transition: 0.3s;
  cursor: pointer;
  transform: scale(1.05);
}

.button:focus {
  outline: none;
}

.button[data-disabled='true'] .disabled {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 0;
}

.button[data-disabled='false'] .disabled {
  display: none;
}
