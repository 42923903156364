.wrapper-playerSubs,
.content-playerSubs {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

@media screen and (max-width: 390px) {
  .vjs-viewer-btn-wrapper {
    display: none;
  }
}
